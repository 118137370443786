import React from 'react';
import { PortraitBar } from './bar';
import { normalizeValue } from './helper';
import { PortraitChartGradients } from './gradients';
import { PortraitChartBackground } from './background';
import { PortraitBarChartFooterText } from './footerText';
import { MAX_BAR_HEIGHT_MOBILE } from '../../../constants';

export function PortraitStandAloneChart({
	config,
	width = 100,
	height = 100,
	getHighestValue,
	highestGroupValue,
	maxSizePercentage,
	isFIA
}) {
	const baseConfig = {
		width: `${width}%` || '100%',
		height: `${height}%` || '100%',
		viewBox: '0 0 279 424',
		maxBarHeight: MAX_BAR_HEIGHT_MOBILE,
		maxWidth: 270,
		barWidth: 72
	};

	const { data } = config;
	const { maxWidth, barWidth, viewBox } = baseConfig;

	const footerConfig = (({ sidebar, data }) => {
		return {
			showVALegend: data.some(datum => datum.type === 'VA'),
			showFIALegend: data.some(datum => datum.type === 'FIA'),
			age: sidebar.age
		};
	})(config);

	const highestValue = highestGroupValue || getHighestValue(data);

	return (
		<div className='vertical-bar-chart-wrapper'>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				preserveAspectRatio='none'
				width={baseConfig.width}
				height={baseConfig.height}
				viewBox={viewBox}
			>
				<PortraitChartGradients />
				<g transform='translate(-8,-2)'>
					<g>
						<g transform='rotate(-90 148.5 162.5)'>
							<PortraitChartBackground {...baseConfig} />

							{data.map((datum, index) => (
								<PortraitBar
									index={index}
									label={datum.label}
									color={datum.color}
									maxWidth={maxWidth}
									barWidth={barWidth}
									dataLength={data.length}
									key={`portrait-bar-${index}`}
									value={normalizeValue(baseConfig.maxBarHeight)(datum.value, highestValue, 0, maxSizePercentage)} />
							))}
						</g>
						<g transform='rotate(-90 147.5 374)'>
							<g>
								<path
									fill='#fff'
									d='M96.5 238.5a4 4 0 0 1 4-4h99v279h-99a4 4 0 0 1-4-4z'
								/>
								<path
									fill='url(#c9d6d)'
									d='M96.5 238.5a4 4 0 0 1 4-4h99v279h-99a4 4 0 0 1-4-4z'
								/>
							</g>
							<g>
								<path
									fill='#1c57a5'
									fillOpacity='.5'
									d='M95.5 236.5a2 2 0 0 1 2-2h2v279h-2a2 2 0 0 1-2-2z'
								/>
							</g>
						</g>
					</g>
					<PortraitBarChartFooterText config={footerConfig} isFIA={isFIA} />
				</g>
			</svg>
		</div>
	);
}