import React from 'react';
import { compose } from '../../utils';
import { withImages } from '../../utils/WithImages';

import './styles.scss';

export function HeaderVA({ images }) {
	return (
		<div className='header-va-wrapper'>
			<label className='form-section-title title-results result-va'>
				Your client’s current contract offers higher guaranteed income than a
				Nationwide New Heights Select contract{' '}
				<span className='no-wrap'>at this time.</span>
			</label>
		</div>
	);
}

export default compose(
	withImages([
		{ id: 'doingIcon', src: '/doing-icon.png', noWrap: true },
		{ id: 'greatIcon', src: '/great-icon.png', noWrap: true }
	])
)(HeaderVA);
