import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, IconButton } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import HintSVG from './hint-svg';

const Hint = ({ text, position, fillColor, classes }) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleTooltipClose = () => {
		setIsOpen(false);
	};
	const handleTooltipOpen = () => {
		setIsOpen(true);
	};

	return (
		<ClickAwayListener onClickAway={handleTooltipClose}>
			<React.Fragment>
				<Tooltip
					title={text}
					placement={position}
					arrow
					onClose={handleTooltipClose}
					tabIndex='-1'
					open={isOpen}
					disableFocusListener
					leaveTouchDelay={5000}
				>
					<IconButton
						disableRipple
						tabIndex='-1'
						onClick={handleTooltipOpen}
						onMouseOver={handleTooltipOpen}
						className={classes ? classes : ''}
						style={{
							backgroundColor: 'transparent',
							position: 'absolute',
							top: '17px',
							padding: '0',
							marginLeft: '5px',
							right: '-21px',
							cursor: 'auto'
						}}
					>
						<HintSVG fill={fillColor} />
					</IconButton>
				</Tooltip>
			</React.Fragment>
		</ClickAwayListener>
	);
};

Hint.propTypes = {
	text: PropTypes.string.isRequired,
	position: PropTypes.string,
	width: PropTypes.string,
	fillColor: PropTypes.string
};

Hint.defaultProps = {
	position: 'top',
	fillColor: '#0047bb'
};

export default Hint;
