import React, { useEffect, useState } from 'react';
import { CheckMarkIcon } from '../CheckMarkIcon';
import { useAppContext } from '../../../contexts/appContext';
import { BROWSER_TYPE, FIA_CONTAINER_SIZE } from '../../../constants';

export function Bar({
	x,
	y,
	id,
	padding,
	legendFits,
	suitableBarWidthForLabel,
	width = 0,
	height = 30,
	checked = false,
	color = '#1c57a5',
	isAlone = false,
	label = '',
	legend = '',
	isFIA
}) {
	const { browserType } = useAppContext();
	const extraMargin = isFIA ? FIA_CONTAINER_SIZE : 0;
	const isBrowserSupported = browserType !== BROWSER_TYPE.IE 
		&& browserType !== BROWSER_TYPE.EDGE;

	const [showText, setShowText] = useState(!isBrowserSupported);
	
	const LABEL_OUTSIDE_SPAN = 5;
	const LABEL_WIDTH = 300;
	const ANIMATION_DURATION_IN_SECONDS = 1;
	const textColor = {
		inner: '#FFF',
		outer: '#000'
	};

	const getLabelTranslate = (labelLength) => {
		const y = `${isAlone ? 61 : 45}`;
		switch (labelLength) {
		case (14):
			return `translate(-125, ${y})`;
		case (12):
			return `translate(-112, ${y})`;
		case (11):
			return `translate(-105, ${y})`;
		case (10):
			return `translate(-105, ${y})`;
		case (8):
			return `translate(-90, ${y})`;
		case (7):
			return `translate(-75, ${y})`;
		case (6):
			return `translate(-67, ${y})`;
		case (5):
			return `translate(-62, ${y})`;
		case (4):
			return `translate(-55, ${y})`;
		case (3):
			return `translate(-45, ${y})`;
		case (2):
			return `translate(-35, ${y})`;
		default:
			return `translate(${isAlone ? '-15, 61' : '-95, 45'})`;
		}
	};
	width = width > 750 ? 750 : width;
	const rect = {
		width,
		height,
		color,
		icon: {
			position: {
				x: (width + x + padding) + extraMargin,
				y: y + (isAlone ? 42 : 27)
			}
		},
		position: {
			x: x + extraMargin,
			y: y,
			transform: `translate(0,${isAlone ? '40' : '25'})`
		},
		label: {
			position: {
				x: (width + x + padding) + extraMargin,
				y: y,
				transform: getLabelTranslate(label.length)
			}
		},
		legend: {
			position: {
				x: legendFits ? (x + padding) + extraMargin : (width + x - (padding * 2)) + extraMargin,
				y: y,
				transform: `translate(20,${isAlone ? '61' : '45'})`
			},
			color: legendFits ? textColor.inner : textColor.outer
		},
	};

	const animationTagIds = [
		`animate1_${id}`,
		`animate2_${id}`
	];
	const animateTimeouts = {};
	useEffect(() => {
		if (isBrowserSupported) {
			animationTagIds.forEach(animationId => {
				const timeoutDuration = ANIMATION_DURATION_IN_SECONDS * 1000;
				const animEle = document.getElementById(animationId);
				animEle.beginElement();
				const timeoutHandler = setTimeout(function () {
					setShowText(true);
				}, timeoutDuration);
				animateTimeouts[animationId] = timeoutHandler;
			});
		}

		return () => {
			Object.keys(animateTimeouts).forEach(timeoutKey => {
				clearTimeout(animateTimeouts[timeoutKey]);
			});
		};
	}, [setShowText, isBrowserSupported]);

	const BarText = ({ label, position, width, text }) => {
		let isLabelOutside = width <= suitableBarWidthForLabel;
		const y = `${isAlone ? 61 : 45}`;

		let labelPositionInX;
		if (isLabelOutside) {
			labelPositionInX = position.x + width + LABEL_OUTSIDE_SPAN;
			if (legend) {
				labelPositionInX += LABEL_WIDTH;
			}
		} else {
			labelPositionInX = label.position.x;
		}
		return (
			<text className={`bar-value-label${isLabelOutside ? '-outside' : ''}`}
				transform={isLabelOutside ? `translate(0, ${y})` : label.position.transform}
				x={labelPositionInX}
				y={label.position.y}
				fill={textColor.inner}>
				{text}
			</text>
		);
	};

	return (
		<g>
			<defs>
				<linearGradient
					id='qeqfa'
					x1='570'
					x2='0'
					y1='15'
					y2='15'
					gradientUnits='userSpaceOnUse'>
					<stop offset='0' stopColor='#fff' stopOpacity='0' />
					<stop offset='1' stopOpacity='0.15' />
				</linearGradient>
			</defs>
			<rect rx='4'
				fill={rect.color}
				x={rect.position.x}
				y={rect.position.y}
				width={rect.width}
				height={rect.height}
				transform={rect.position.transform}
			>
				<animate
					id={animationTagIds[0]}
					attributeName='width'
					begin={`${animationTagIds[0]}.begin`}
					from='0'
					to={rect.width}
					dur={`${ANIMATION_DURATION_IN_SECONDS}s`}
					fill='freeze' />
			</rect>
			<rect rx='4'
				transform={rect.position.transform}
				x={rect.position.x}
				y={rect.position.y}
				width={rect.width}
				height={rect.height}
				fill='url(#qeqfa)'>
				<animate
					id={animationTagIds[1]}
					attributeName='width'
					begin={`${animationTagIds[1]}.begin`}
					from='0'
					to={rect.width}
					dur={`${ANIMATION_DURATION_IN_SECONDS}s`}
					fill='freeze' />
			</rect>
			{showText && (<>
				<BarText positionInY={y} text={label} {...rect} />
				<text className='bar-value-label'
					transform={rect.legend.position.transform}
					x={rect.legend.position.x}
					y={rect.legend.position.y}
					fill={rect.legend.color}>
					{legend}
				</text>
				{checked ? <CheckMarkIcon x={rect.icon.position.x} y={rect.icon.position.y} /> : null}
			</>)}
		</g>
	);
}