import * as Yup from 'yup';
import { OWNER_TYPES, CALCULATION_METHODS } from '../../../src/constants';

const validationSchema = Yup.object({
	CurrentAge: Yup.number()
		.min(40, 'Minimum age 40')
		.max(80, 'Maximum age 80')
		.required(true),
	LifeTimeIncomeStartAge: Yup.number()
		.required(true)
		.test(function (LifeTimeIncomeStartAge) {
			const age = this.parent.CurrentAge + 1;
			const yearsOfDeferral = LifeTimeIncomeStartAge - this.parent.CurrentAge;
			if (LifeTimeIncomeStartAge < 50 && age < 50) {
				return this.createError({
					path: this.path,
					message: 'Minimum age 50'
				});
			}
			if (age > LifeTimeIncomeStartAge) {
				return this.createError({
					path: this.path,
					message: `Minimum age ${age}`
				});
			}
			if (LifeTimeIncomeStartAge > 99) {
				return this.createError({
					path: this.path,
					message: 'Maximum age is 99'
				});
			}
			if (yearsOfDeferral > 29) {
				return this.createError({
					path: this.path,
					message: `Maximum age is ${age + 28}`
				});
			}
			if (this.parent.OwnerType === OWNER_TYPES.JOINT) {
				const jointStartAge = this.parent.JointCurrentAge + yearsOfDeferral;
				if (jointStartAge < 50) {
					return this.createError({
						path: this.path,
						message: `Minimum age ${
							LifeTimeIncomeStartAge + (50 - jointStartAge)
						}`
					});
				}
			}
			return {};
		}),
	OwnerType: Yup.string().required(),
	State: Yup.string().required(),
	JointCurrentAge: Yup.number()
		.when('OwnerType', {
			is: val => val === OWNER_TYPES.JOINT,
			then: Yup.number().required(true)
		})
		.when('OwnerType', {
			is: val => val === OWNER_TYPES.JOINT,
			then: Yup.number().min(40, 'Minimum age is 40')
		})
		.when('OwnerType', {
			is: val => val === OWNER_TYPES.JOINT,
			then: Yup.number().max(80, 'Maximum age is 80')
		}),
	Carrier: Yup.string().required(),
	ProductName: Yup.string().required(),
	Month: Yup.string().required(),
	Day: Yup.number().required(),
	Year: Yup.string().required(),
	CalculationMethod: Yup.string().required(),
	ContractCashValue: Yup.number()
		.min(1, 'Minimum value is 1')
		.max(9999999, 'Maximum value is $9,999,999')
		.required(true),
	IncomeBenefitBase: Yup.number()
		.min(1, 'Minimum value is 1')
		.max(9999999, 'Maximum value is $9,999,999')
		.required(true),
	IncomeBaseRollupRate: Yup.number()
		.max(30, 'Maximum value is 30%')
		.required(true),
	TotalRollupPeriodTerm: Yup.number()
		.when('NoRollUp', {
			is: val => !val,
			then: Yup.number().required(true)
		})
		.when('NoRollUp', {
			is: val => !val,
			then: Yup.number().max(30, 'Maximum value is 30 year')
		}),
	PayoutIncomeStart: Yup.number()
		.min(0.01, 'Minimum value is 0.01%')
		.max(30, 'Maximum value is 30%')
		.required(true),
	SurrenderValue: Yup.number()
		.min(1, 'Minimum value is 1')
		.max(9999999, 'Maximum value is $9,999,999')
		.required(true),
	InitialIncomeBenefitBase: Yup.number()
		.when('CalculationMethod', {
			is: val => val === CALCULATION_METHODS.SIMPLE,
			then: Yup.number().required(true)
		})
		.when('CalculationMethod', {
			is: val => val === CALCULATION_METHODS.SIMPLE,
			then: Yup.number().min(1, 'Minimum value is 1')
		})
		.when('CalculationMethod', {
			is: val => val === CALCULATION_METHODS.SIMPLE,
			then: Yup.number().max(9999999, 'Maximum value is $9,999,999')
		})
});

export default validationSchema;
