import axios from 'axios';

export async function doRequest(options) {
	const config = {
		headers: { 'Content-Type': 'application/json' },
		responseType: 'json',
		...options
	};

	return axios.request(config).then(response => {
		return response ? response.data : null;
	});
}
