import React from 'react';

export function PortraitChartGradients() {
	return (
		<defs>
			<linearGradient
				id='c9d6a'
				x1='-11.5'
				x2='308.5'
				y1='161.5'
				y2='161.5'
				gradientUnits='userSpaceOnUse'
			>
				<stop offset='0' stopColor='#fff' stopOpacity='0' />
				<stop offset='1' stopOpacity='.03' />
			</linearGradient>
			<linearGradient
				id='c9d6b'
				x1='149'
				x2='-11'
				y1='212.5'
				y2='212.5'
				gradientUnits='userSpaceOnUse'
			>
				<stop offset='0' stopColor='#fff' stopOpacity='0' />
				<stop offset='1' stopOpacity='.15' />
			</linearGradient>
			<linearGradient
				id='c9d6c'
				x1='149'
				x2='-11'
				y1='113'
				y2='113'
				gradientUnits='userSpaceOnUse'
			>
				<stop offset='0' stopColor='#fff' stopOpacity='0' />
				<stop offset='1' stopOpacity='.15' />
			</linearGradient>
			<linearGradient
				id='c9d6d'
				x1='96.5'
				x2='199.5'
				y1='374'
				y2='374'
				gradientUnits='userSpaceOnUse'
			>
				<stop offset='0' stopColor='#fff' stopOpacity='0' />
				<stop offset='1' stopOpacity='.08' />
			</linearGradient>
		</defs>
	);
}