import React from 'react';
import { matchPath } from 'react-router';

const renderer = ({ breadcrumb, match }) => {
	if (typeof breadcrumb === 'function') { return breadcrumb({ match }); }
	return breadcrumb;
};

export const getBreadcrumbs = ({ routes, pathName }) => {
	const matches = [];

	const [homeBreadcrumb] = routes.filter(route => route.path === '/');
	if (homeBreadcrumb) {
		const { breadcrumb, path } = homeBreadcrumb;
		const match = matchPath(homeBreadcrumb.path, { exact: true, path: homeBreadcrumb.path });
		matches.push({
			breadcrumb: renderer({ breadcrumb, match }),
			path,
			match
		});
	}

	pathName
		.replace(/\/$/, '')
		.split('/')
		.reduce((prev, curr) => {
			const pathSection = `${prev}/${curr}`;

			let breadcrumbMatch;

			routes.some(({ breadcrumb, path }) => {
				const match = matchPath(pathSection, { exact: true, path });

				if (match) {
					breadcrumbMatch = {
						breadcrumb: renderer({ breadcrumb, match }),
						path,
						match,
						active: pathName === path
					};
					return true;
				}
				return false;
			});

			if (breadcrumbMatch) {
				matches.push(breadcrumbMatch);
			}
			return pathSection;
		});
	return matches;
};

export const withBreadcrumbs = routes => ScreenComponent => props => {
	return <ScreenComponent {...props}
		breadcrumbs={getBreadcrumbs({ pathName: props.location.pathname, routes })} />;
};