import React from 'react';

import './styles.scss';
import { withContext } from '../../../contexts/appContext';
import { compose } from '../../../utils';
import { CALCULATION_METHODS, FIA } from '../../../constants';

export function CommonFooter() {
	return (
		<div>
			<p className='bold-text'>
				FOR REGISTERED REP AND RIA USE ONLY -{' '}
				<span className='no-wrap'>NOT FOR USE WITH THE PUBLIC</span>
			</p>
		</div>
	);
}

export function ResultFooter({ analyzerPayload }) {
	const calculationMethod =
		analyzerPayload.input.CalculationMethod === CALCULATION_METHODS.SIMPLE
			? CALCULATION_METHODS.SIMPLE
			: CALCULATION_METHODS.COMPOUNDED;
	const incomeBaseRollupRate = (
		analyzerPayload.input.IncomeBaseRollupRate * 100
	)
		.toString()
		.match(/^-?\d+(?:\.\d{0,2})?/);
	const isFIA = analyzerPayload?.input?.CurrentType === FIA;
	return (
		<div>
			<p className='bold-text'>				
				FOR FINANCIAL PROFESSIONAL AND RIA USE ONLY - NOT FOR USE WITH THE PUBLIC
			</p>
			<p>
				This material is not a recommendation to buy or sell a financial product
				or to adopt an investment strategy. Investors should discuss their specific
				situation with their financial professional.
			</p>
			<p>
				<sup>1 </sup>Lifetime income is calculated using the High Point Income
				Benefit Base (see definition below). A 30% Minimum Income Benefit Value
				bonus, credited at contract issue and calculated upon the purchase
				payment, will be added to your guaranteed minimum income benefit value.
				The High Point Income Benefit Base is the greater of the guaranteed
				Minimum Income Benefit Value and the Highest DAV. The Highest DAV is the
				highest Daily Accumulation Value (DAV) adjusted for withdrawals. The
				DAV is the greater of the contract value plus any strategy earnings that
				have not been credited to the contract or the Return of Purchase Payment
				Guarantee amount. Lifetime income will be based on the High Point Income
				Benefit Base multiplied by a Lifetime Payout Percentage. Lifetime Payout
				Percentages increase each year you wait to take lifetime income up to
				age 90. Minimum age of 50 before starting lifetime income. Lifetime
				income benefit payments are available on a monthly basis. Guarantees and
				protections are subject to the claims-paying ability of the issuing
				company.
			</p>
			<p>
				<sup>2 </sup>High Point 365 Select with Bonus has
				an annual rider charge of 1.10% for the life of the contract, calculated
				on the High Point Income Benefit Base and deducted from the Contract
				Value. Withdrawals will reduce the Contract Value and may reduce the
				High Point Income Benefit Base.
			</p>
			<p>
				<sup>3 </sup>Income Base comparison based on the High Point 365 Select with
				Bonus minimum income benefit value using the Surrender Value provided by
				you with a 30% Minimum Income Benefit Value bonus and 8% compound annual
				growth in years 1-10; and the {isFIA ? 'FIA' : 'VA' } Income Base value provided by you with{' '}
				{incomeBaseRollupRate}%{' '}
				<span className='lowercase'>{calculationMethod}</span> annual growth for
				any remaining years in the Roll-up Term provided by you.
			</p>
			<p>
				Guarantees and protections are subject to the claims-paying ability of
				Nationwide Life and Annuity Insurance Company.
			</p>
		</div>
	);
}

export default compose(withContext())(ResultFooter);
