import React from 'react';
import { ListItem } from '@material-ui/core';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { EmptyCheckBoxSvg, FilledCheckBoxSvg } from './checkBox-svg';

const ListItemCheckbox = ({
	field,
	form,
	label,
	showHelp,
	textHint,
	hintPosition,
	mobileTooltip,
	children,
	disabled,
	handleAfterOnChange,
	...rest
}) => {
	const handleOnClick = event => {
		if (!disabled) {
			form.setFieldValue(field.name, !field.value);
			form.setFieldTouched(field.name, true);
			if (handleAfterOnChange) handleAfterOnChange(event);
		}
	};
	return (
		<ListItem
			onClick={handleOnClick}
			disabled={disabled}
			className='checkbox-container'
		>
			<FormControlLabel
				className='checkbox-label'
				control={
					<Checkbox
						disabled={disabled}
						color='primary'
						icon={<EmptyCheckBoxSvg />}
						checkedIcon={<FilledCheckBoxSvg />}
						{...field}
						{...rest}
						value=''
						checked={field.value}
					/>
				}
			/>
			<span className='label-checkBox'>{label}</span>
			{children}
		</ListItem>
	);
};

ListItemCheckbox.propTypes = {
	form: PropTypes.object,
	field: PropTypes.object,
	label: PropTypes.string,
	showHelp: PropTypes.bool,
	partialSelect: PropTypes.bool,
	textHint: PropTypes.object,
	hintPosition: PropTypes.string
};

const ListItemCheckboxFormik = props => (
	<Field component={ListItemCheckbox} {...props} />
);

export default ListItemCheckboxFormik;
