import React from 'react';

import isEmpty from 'lodash/isEmpty';

import { Button, CircularProgress } from '@material-ui/core';

export function SubmitButton({
	payload,
	loading,
	dirty,
	errors,
	handleOnClickSubmitForm
}) {
	const isDirty = dirty || !isEmpty(payload);

	const disabled = isDirty === false || loading;
	return (
		<div className='view-results-button-wrapper'>
			<Button
				className='view-results-button'
				disableRipple
				disableFocusRipple
				disableTouchRipple
				type='submit'
				disabled={disabled}
				onClick={() => handleOnClickSubmitForm(errors)}
			>
				{loading && (
					<CircularProgress
						color='inherit'
						className='circle-progress'
						size={15}
					/>
				)}
				{'View Results'}
			</Button>
		</div>
	);
}
