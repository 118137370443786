import React from 'react';
import Lottie from 'react-lottie';
import { Container, Grid, Button } from '@material-ui/core';
import * as animationData from './magnifying-glass.json';
import './styles.scss';

export const NotFound = ({ history }) => {
	return (
		<Container className='not-found-wrapper'>
			<Grid container>
				<Grid item xs={12} sm={12}>
					<div className='animation-container'>
						<Lottie
							options={{
								loop: true,
								autoplay: true,
								animationData: animationData.default,
								rendererSettings: {
									preserveAspectRatio: 'xMidYMid slice'
								}
							}}
						/>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} className='not-found-section'>
					<p className='title'>Page not found</p>
					<p className='description'>
                        The page you are looking for doesn't exist or is unavailable.
					</p>
					<p className='description m-b-25 instruction-description'>
                        Try searching for the page from our homepage.
					</p>
					<Button className='not-found-button' onClick={() => history.push('/')}>
                        Go to homepage
					</Button>
				</Grid>
			</Grid>

		</Container>
	);
};