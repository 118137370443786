import React from 'react';
import { SidebarSection } from './sidebar';
import { ChartBackground } from './background';

export function Chart({ viewBox, children, width, height, sidebarConfig, isFIA }) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox={`0 0 ${viewBox.width} ${viewBox.height}`} width={width} height={height} version='1.1'>
			<ChartBackground height={viewBox.height} />
			<g>
				{children}
			</g>
			<SidebarSection height={viewBox.height} width={viewBox.sidebarWidth} {...sidebarConfig} isFIA={isFIA} />
		</svg >
	);
};