import React, { useState, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';

import { CDN_URL } from '../config';

const getImage = src => {
	return new Promise((resolve, reject) => {
		const image = new Image();

		image.onload = function() {
			resolve(image);
		};

		image.onerror = function(err) {
			console.log('Error Image loaded', src, err);
			reject();
		};

		image.src = src;
	});
};

export const withImages = resources => ScreenComponent => props => {
	const [images, setImages] = useState({});
	useEffect(() => {
		async function loadImages() {
			const allImages = await resources.reduce(async (acc, curr) => {
				const result = await acc;
				if (typeof curr.src === 'string') {
					const url = `${CDN_URL}/images${curr.src}`;
					const img = await getImage(url);
					result[curr.id] = curr.noWrap === true ? img.src : `url(${img.src})`;
					return result;
				}
				const src = await curr.src;
				const img = await getImage(src.default);
				result[curr.id] = curr.noWrap === true ? img.src : `url(${img.src})`;
				return result;
			}, Promise.resolve({}));
			setImages(allImages);
		}
		loadImages();
	}, []);
	return isEmpty(images) ? (
		<div />
	) : (
		<ScreenComponent {...props} images={images} />
	);
};
