import React from 'react';
import { BarChart } from '../../shared/BarChart';
import { PortraitBarChart } from '../../shared/PortraitBarChart';
import { ORIENTATION, ANALYZER_RESULT_KEYS } from '../../../constants';

export function ResultsBarChart({
	sectionKey,
	chartConfig,
	orientation,
	highestGlobalValue,
	maxSizePercentage,
	isFIA
}) {
	const getHighestValue = (chartData) => {
		const highestVal = chartData.reduce((prev, cur) => {
			const { value } = cur;
			return value > prev ? value : prev;
		}, 0);
		const nextHighestValue = (highestVal / 10) + highestVal;
		return nextHighestValue;
	};

	const mergeData = config => config.reduce((prev, curr) => prev.concat(curr.data), []);

	const renderBarChartComponent = config => {
		const BarChartComponent = orientation === ORIENTATION.HORIZONTAL ? BarChart : PortraitBarChart;

		if (config instanceof Array) {
			const chartData = mergeData(config);

			let highestGroupValue = 0;
			highestGroupValue = sectionKey === ANALYZER_RESULT_KEYS.incomeBenefitBase
				? highestGroupValue = highestGlobalValue
				: highestGroupValue = getHighestValue(chartData);

			if (orientation === ORIENTATION.VERTICAL) {
				return (<BarChartComponent
					config={config}
					sectionKey={sectionKey}
					getHighestValue={getHighestValue}
					highestGroupValue={highestGroupValue}
					maxSizePercentage={maxSizePercentage}
					isFIA={isFIA} />);
			}

			return config.map((chartConfig, index) => {
				const barChartKey = `bar-chart-${index}`;
				const fragmentKey = `chart-fragment-${index}`;
				const chartDividerKey = `chart-divider-${index}`;
				return (
					<React.Fragment key={fragmentKey}>
						<BarChartComponent
							key={barChartKey}
							config={chartConfig}
							sectionKey={`${sectionKey}-${index}`}
							getHighestValue={getHighestValue}
							highestGroupValue={highestGroupValue}
							maxSizePercentage={maxSizePercentage}
							isFIA={isFIA}	/>
						{index === 0
							? <div key={chartDividerKey} className='chart-divider'></div>
							: null}
					</React.Fragment>);
			});
		}
		if (sectionKey === ANALYZER_RESULT_KEYS.contractCashValue) {
			return <BarChartComponent
				config={config}
				sectionKey={sectionKey}
				highestGroupValue={highestGlobalValue}
				maxSizePercentage={maxSizePercentage}
				getHighestValue={getHighestValue}
				isFIA={isFIA} />;
		}
		if (sectionKey === ANALYZER_RESULT_KEYS.payoutPercentage) {
			const MAX_ALLOWED_PERCENTAGE = 0.30;
			return <BarChartComponent
				config={config}
				sectionKey={sectionKey}
				highestGroupValue={MAX_ALLOWED_PERCENTAGE}
				maxSizePercentage={maxSizePercentage}
				getHighestValue={getHighestValue}
				isFIA={isFIA} />;
		}
		return <BarChartComponent
			config={config}
			sectionKey={sectionKey}
			maxSizePercentage={maxSizePercentage}
			getHighestValue={getHighestValue}
			isFIA={isFIA}/>;
	};

	return (renderBarChartComponent(chartConfig));
}