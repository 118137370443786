import axios from 'axios';

import jwtDecode from 'jwt-decode';

import URLSearchParams from '@ungap/url-search-params';

import qs from 'query-string';

import _ from 'lodash';

import { bindEvent, removeEvent, inIframe } from '../utils';

const TOKEN_NAME = 'VA_EXCHANGE_TOKEN';

export class Auth {
	constructor({ SSO_URL }) {
		this.authUrl = SSO_URL;
	}

	isAuthenticated() {
		const token = this.getToken();
		return token && !this.isTokenExpired(token);
	}

	isTokenExpired(token) {
		try {
			const { exp } = jwtDecode(token);
			const currentTime = Date.now() / 1000;
			return exp < currentTime;
		} catch (err) {
			return true;
		}
	}

	getDataLayer(token) {
		return jwtDecode(token);
	}

	setToken(token) {
		if (token) {
			sessionStorage.setItem(TOKEN_NAME, token);
		}
	}

	getToken() {
		let token;

		try {
			const parsedURL = qs.parse(window.location.search);
			token = parsedURL.token || null;
		} catch (error) {
			const params = new URLSearchParams(window.location.search);
			token = params.get('token') || null;
		}

		this.setToken(token);

		const jwtToken = token || sessionStorage.getItem(TOKEN_NAME);

		if (jwtToken === 'null' || _.isEmpty(jwtToken)) {
			return null;
		}

		return jwtToken;
	}

	logout() {
		sessionStorage.removeItem(TOKEN_NAME);
	}

	redirectToLogin() {
		const currentPath = window.location.href;
		window.location.href = `${this.authUrl}${currentPath}`;
	}

	tryTriggerRefresh() {
		if (inIframe()) {
			window.parent.postMessage('Get new access token', '*');
		}
	}
}

class RefreshTokenHelper {
	constructor({ onTimeout }) {
		this.id = 'refreshTokenIFrame';
		this.isRefreshing = false;
		this.timeout = null;
		this.listeners = [];
		this.onTimeout = onTimeout;
		this.onMessageReceived = this.onMessageReceived.bind(this);
	}

	onMessageReceived() {
		if (this.timeout) {
			clearTimeout(this.timeout);
			this.timeout = null;
		}

		this.isRefreshing = false;

		this.callListeners();

		this.removeIFrame();
	}

	refresh(authUrl) {
		this.isRefreshing = true;
		this.createIFrame(authUrl);
		this.startTimeout();
	}

	callListeners() {
		this.listeners.forEach(cb => cb());
		this.listeners = [];
	}

	removeIFrame() {
		const iframeElement = document.getElementById(this.id);
		if (document.body.contains(iframeElement)) {
			iframeElement.parentElement.removeChild(iframeElement);
		}
		removeEvent(window, 'message', this.onMessageReceived);
	}

	createIFrame(url) {
		const iframe = document.createElement('iframe');
		iframe.setAttribute('src', url);
		iframe.setAttribute('id', this.id);
		iframe.style.display = 'none';
		bindEvent(window, 'message', this.onMessageReceived);
		document.body.appendChild(iframe);
	}

	startTimeout() {
		this.timeout = setTimeout(() => {
			if (this.isRefreshing && this.timeout) {
				clearTimeout(this.timeout);
				this.timeout = null;
				this.isRefreshing = false;
				this.onTimeout && this.onTimeout();
			}
		}, 15000);
	}

	addListener(callback) {
		this.listeners.push(callback);
	}
}

export class Api {
	constructor({ auth, baseURL }) {
		this.auth = auth;
		this.refreshTokenHelper = new RefreshTokenHelper({
			onTimeout: this.onRefreshTimeout.bind(this)
		});
		axios.defaults.baseURL = baseURL;
		axios.interceptors.request.use(
			this.onRequest.bind(this),
			this.onRequestError.bind(this)
		);
		axios.interceptors.response.use(
			this.onResponse.bind(this),
			this.onResponseError.bind(this)
		);
	}

	onRefreshTimeout() {
		this.auth.redirectToLogin();
	}

	onRequestError(error) {
		return Promise.reject(error);
	}

	onResponseError(error) {
		const {
			config,
			response: { status }
		} = error;

		if (status === 401) {
			if (!this.refreshTokenHelper.isRefreshing) {
				this.auth.logout();
				this.refreshTokenHelper.refresh(this.auth.authUrl);
			}
			return new Promise(resolve => {
				this.refreshTokenHelper.addListener(() => {
					const refreshToken = this.auth.getToken();
					config.headers.Authorization = `Bearer ${refreshToken}`;
					config.baseURL = undefined;
					resolve(axios.request(config));
				});
			});
		}
		return Promise.reject(error);
	}

	onRequest(config) {
		const token = this.auth.getToken();

		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}

		return config;
	}

	onResponse(response) {
		return response;
	}
}
