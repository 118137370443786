import React, { useState } from 'react';
import { useResize } from 'react-browser-hooks';
import Button from '@material-ui/core/Button';
import { compose } from '../../utils';
import { withFooter } from '../../utils/WithFooter';
import { withContext } from '../../contexts/appContext';
import { withNavigation } from '../../utils/WithNavigation';
import { withImages } from '../../utils/WithImages';
import { setUserAgreementVal } from '../../api/UserAgreementService';
import UserAgreement from '../../components/shared/UserAgreement';
import { saveAnalyzerPayloadOnLocalStorage } from '../../api/AnalyzerService';

import { Animated } from 'react-animated-css';
import { AnimatedOnScroll } from 'react-animated-css-onscroll';

import './styles.scss';

export function Home({
	userAgrees,
	setUserAgrees,
	navigateTo,
	images,
	setAnalyzerPayload
}) {
	const [showAgreement, setShowAgreement] = useState(false);
	const { width } = useResize();
	let mobileSize = width < 575;

	const handleAgreementAction = (value, callback) => {
		setUserAgreementVal(value).then(() => {
			setUserAgrees(value);
			callback && callback();
		});
	};

	const handleAgreement = () => {
		handleAgreementAction(true, () => {
			setShowAgreement(false);
			navigateTo('/analyzer/info', {}, true);
		});
	};

	const handleCancelAgreement = () => {
		setShowAgreement(false);
	};

	const renderAgreement = () => {
		return (
			<UserAgreement
				visible={showAgreement === true && userAgrees === false}
				onAgree={handleAgreement}
				onCancel={handleCancelAgreement}
			/>
		);
	};

	const handleClick = () => {
		if (showAgreement === false && userAgrees === false) {
			setShowAgreement(true);
			return;
		}
		const emptyPayload = { input: null, output: null };
		setAnalyzerPayload(emptyPayload);
		saveAnalyzerPayloadOnLocalStorage(emptyPayload);
		return navigateTo('/analyzer/info', {}, true);
	};

	return (
		<React.Fragment>
			{renderAgreement()}
			<div
				className='panel-container home-content'
				style={{
					backgroundImage: mobileSize
						? images.homeBlurBackground
						: images.homeBackground
				}}
			>
				<div className='home-content-items container'>
					<h1>New Heights Select</h1>
					<h2>Guaranteed Income Comparison Tool</h2>
					<div id='divider'></div>
					<Animated
						animationIn='fadeInUp'
						animationInDelay={1200}
						animationInDuration={800}
						isVisible
					>
						<p className='animated-p'>
							As solutions to reduce clients' market and longevity risk evolve,
							it makes sense to evaluate if your clients have options that may
							offer greater levels of guaranteed income with greater protection
							for the money they've worked so hard for.
							<br />
						</p>
					</Animated>
					<Animated
						animationIn='fadeInUp'
						animationInDelay={1300}
						animationInDuration={800}
						isVisible
					>
						<p className='animated-p'>
							This tool is designed to help you find out whether Nationwide New
							Heights® Select featuring Nationwide High Point 365® Select with Bonus may offer
							greater income or protection from market losses than your client's
							current solution for guaranteed retirement income using an annual
							roll-up.
						</p>
					</Animated>
					<AnimatedOnScroll
						animationIn='fadeInUp'
						animationInDelay={1700}
						animationInDuration={800}
						isVisible
					>
						<Button className='get-started-button' onClick={handleClick}>
							Get Started
						</Button>
					</AnimatedOnScroll>
					<Animated
						animationIn='fadeInUp'
						animationInDelay={1400}
						animationInDuration={800}
						isVisible
					>
						<p className='animated-p disclaimer'>
							This analysis doesn't consider the potential impact of starting a
							new Surrender Charge period, re-setting any income benefit base, different
							product features, including but not limited to the loss of
							potential accumulated death benefit value in an existing annuity
							contract or differences in guaranteed annuitization rates,
							investment options, rider charges or ability to submit subsequent
							purchase payments.
						</p>
					</Animated>
				</div>
			</div>
		</React.Fragment>
	);
}

export default compose(
	withFooter('home-footer'),
	withImages([
		{ id: 'homeBackground', src: '/home-background-v2.jpg' },
		{ id: 'homeBlurBackground', src: '/home-blur-background-v2.jpg' }
	]),
	withNavigation(),
	withContext()
)(Home);
