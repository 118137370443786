import React from 'react';

import { GO_BACK } from '../constants';

const createNavigateFunction = (history) => (
	path,
	params = {},
	scrollToUp = true
) => {
	if (path === GO_BACK) {
		history.goBack();
	} else {
		history.push(path, params);
	}
	if (scrollToUp) {
		window.scrollTo(0, 0); // TODO: Figure out how to avoid do this or a better way to move the user to the top of the screen
	}
};

export const withNavigation = () => (ScreenComponent) => {
	return (props) => {
		const navigateTo = createNavigateFunction(props.history);
		return <ScreenComponent {...props} navigateTo={navigateTo} />;
	};
};
