import React, { useState } from 'react';
import BrowserDetection from 'react-browser-detection';

export function PortraitBarChartFooterText({
	config,
	isFIA
}) {
	const fiaLabel = isFIA ? 'Nationwide FIA' : 'FIA';
	const vaLabel = isFIA ? 'Current FIA' : 'VA';
	const extraFIAMargin = !isFIA ? 40 : 0;
	const extraVAMargin = !isFIA ? 27 : 0;
	const [browserTranslate, setBrowserTranslate] = useState({
		labelY: 337
	});

	const browserHandler = {
		ie: () => {
			setBrowserTranslate({
				labelY: 347
			});
			return (null);
		},
		edge: () => {
			setBrowserTranslate({
				labelY: 347
			});
			return (null);
		}
	};

	const renderLabels = ({
		showVALegend,
		showFIALegend
	}) => {
		return (
			<>
				{showFIALegend ? (
					<g>
						<g>
							<g>
								<text
									dominantBaseline='text-before-edge'
									style={{ fontKerning: 'normal' }}
									fill='#555'
									fontFamily="'Gotham-Book','Gotham-Bold'"
									fontSize='14'
									transform={`translate(${40 + extraFIAMargin} ${browserTranslate.labelY})`}
								>
									<tspan style={{ fontKerning: 'normal', fontWeight: 'bold' }}>{fiaLabel}</tspan>
								</text>
							</g>
						</g>
					</g>
				) : null}
				<g {...(!showFIALegend && showVALegend ? { transform: 'translate(-50,0)' } : null)}>
					<g>
						<g>
							<text
								dominantBaseline='text-before-edge'
								style={{ fontKerning: 'normal' }}
								fill='#555'
								fontFamily="'Gotham-Book','Gotham-Bold'"
								fontSize='14'
								transform={`translate(${163 + extraVAMargin} ${browserTranslate.labelY})`}
							>
								<tspan style={{ fontKerning: 'normal', fontWeight: 'bold' }}>{vaLabel}</tspan>
							</text>
						</g>
					</g>
				</g>
			</>
		);
	};

	return (
		<>
			<BrowserDetection>
				{browserHandler}
			</BrowserDetection>
			<g>
				{renderLabels(config)}
				<g>
					<text
						dominantBaseline='text-before-edge'
						style={{ fontKerning: 'normal' }}
						fill='#555'
						fontFamily="'Gotham-Book','Gotham-Bold'"
						fontSize='14'
						transform='translate(116 384)'
					>
						<tspan fontFamily="'Gotham-Book','Gotham-Light'">at</tspan>
						<tspan style={{ fontWeight: 'bold' }} fontFamily="'Gotham-Book','Gotham-Bold'"> Age {config.age}</tspan>
					</text>
				</g>
			</g>
		</>
	);
}