import React, { useEffect, lazy, Suspense } from 'react';

import ReactDOM from 'react-dom';

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import TagManager from 'react-gtm-module';

import { ThemeProvider } from '@material-ui/core/styles';

import 'react-app-polyfill/ie11';

import 'react-app-polyfill/stable';

import './styles/scss/main.scss';

import 'bootstrap-css-only/css/bootstrap.min.css';

import registerServiceWorker from './registerServiceWorker';

import { AppContextComponent, withContext } from './contexts/appContext';

import { getAnalyzerPayload } from './api/AnalyzerService';

import { getSessionInfo } from './api/SessionService';

import { DefaultLayout } from './screens';

import { SSO_URL, API_URL } from './config';

import { Auth, Api } from './api/ApiBase';

import { removeParamFromURL } from './utils';

import theme from './styles/materialTheme';

import { tryLoadFonts } from './utils';

import { NotFound } from './screens/NotFound';

const baseUrl = process.env.PUBLIC_URL;

const rootElement = document.getElementById('root');

const auth = new Auth({
	SSO_URL
});

const api = new Api({
	auth,
	baseURL: API_URL
});

const Home = lazy(() => import('./screens/Home'));

const AnalyzerInfo = lazy(() => import('./screens/AnalyzerInfo'));

const AnalyzerResults = lazy(() => import('./screens/AnalyzerResults'));

const SetupApp = withContext()(({ userInfo, setUserInfo, setUserAgrees }) => {
	let isAuthenticated = auth.isAuthenticated();

	useEffect(() => {
		if (!isAuthenticated) {
			auth.redirectToLogin();
			return;
		}

		const token = auth.getToken();

		auth.tryTriggerRefresh();

		getSessionInfo()
			.then(info => {
				setUserInfo(info);
				setUserAgrees(info.agreementAccepted);
			})
			.catch(err => console.warn(err));

		const userData = auth.getDataLayer(token);
		const { anx_code, field_ods_key, field_salesforce_id } = userData;
		// Google tag manager
		const tagManagerArgs = {
			gtmId: process.env.REACT_APP_GTM_CONTAINER_ID,
			dataLayer: {
				field_code: anx_code,
				field_ods_key: field_ods_key,
				field_salesforce_id: field_salesforce_id
			}
		};
		TagManager.initialize(tagManagerArgs);

		window.history.replaceState(
			null,
			null,
			removeParamFromURL('token', window.location.href)
		);
	}, [isAuthenticated, setUserAgrees, setUserInfo]);

	if (!isAuthenticated || !userInfo) {
		return <div />;
	}

	return (
		<BrowserRouter basename={baseUrl}>
			<Suspense fallback={<div />}>
				<Switch>
					<Route exact path={['/analyzer/info', '/analyzer/info/results', '/', '/not-found']}>
						<DefaultLayout>
							<Route
								exact
								path='/analyzer/info'
								component={AnalyzerInfo}
							/>
							<Route
								exact
								path='/analyzer/info/results'
								component={AnalyzerResults}
							/>
							<Route exact path='/' component={Home} />
							<Route path='/not-found' component={NotFound} />
						</DefaultLayout>
					</Route>
					<Redirect to='/not-found' />
				</Switch>
			</Suspense>
		</BrowserRouter>
	);
});

(async function () {
	const [defaultAnalyzerData] = await Promise.all([getAnalyzerPayload()]);

	const initialData = {
		footerName: 'home-footer',
		defaultAnalyzerData,
		api,
		auth
	};

	await tryLoadFonts();

	ReactDOM.render(
		<ThemeProvider theme={theme}>
			<AppContextComponent initialData={initialData}>
				<SetupApp />
			</AppContextComponent>
		</ThemeProvider>,
		rootElement
	);
})();

//registerServiceWorker(); Commented for error in azure
