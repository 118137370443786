import React from 'react';
import Paper from '@material-ui/core/Paper/Paper';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { filterItem } from './helper';

export const MenuItems = ({
	itemToKey,
	inputValue,
	suggestions,
	getItemProps,
	getMenuProps,
	itemToString,
	highlightedIndex
}) => (
	<Paper className='suggestions' {...getMenuProps()}>
		{suggestions
			.filter((suggestion, index, allSuggestions) =>
				filterItem(inputValue, suggestion, allSuggestions, itemToString),
			)
			.map((suggestion, index) => {
				const isHighlighted = highlightedIndex === index;
				const itemProps = getItemProps({
					index,
					item: suggestion,
					selected: highlightedIndex === null ? index === 0 : isHighlighted
				});
				return (
					<MenuItem {...itemProps} key={itemToKey(suggestion)}>
						{itemToString(suggestion)}
					</MenuItem>
				);
			})}
	</Paper>
);