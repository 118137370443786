import React from 'react';

export function PortraitChartBackground({
	maxWidth
}) {
	return (
		<>
			<g>
				<path
					fill='#f9fafb'
					d={`M-11.5 22.5h316a4 4 0 0 1 4 4v${maxWidth}a4 4 0 0 1-4 4h-316z`}
				/>
				<path
					fill='url(#c9d6a)'
					d={`M-11.5 22.5h316a4 4 0 0 1 4 4v${maxWidth}a4 4 0 0 1-4 4h-316z`}
				/>
			</g>
			<g opacity='.5'>
				<path
					fill='none'
					stroke='#979797'
					strokeDasharray='8 4'
					strokeLinecap='square'
					strokeMiterlimit='10'
					strokeWidth='.5'
					d='M141.5 298.5v-274'
				/>
			</g>
			<g opacity='.5'>
				<path
					fill='none'
					stroke='#979797'
					strokeDasharray='8 4'
					strokeLinecap='square'
					strokeMiterlimit='10'
					strokeWidth='.5'
					d='M52.5 298V25'
				/>
			</g>
			<g opacity='.5'>
				<path
					fill='none'
					stroke='#979797'
					strokeDasharray='8 4'
					strokeLinecap='square'
					strokeMiterlimit='10'
					strokeWidth='.5'
					d='M232.5 298V24'
				/>
			</g>
		</>
	);
}