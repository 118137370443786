const env = process.env.NODE_ENV;

let config = null;

try {
	config = require(`./env/${env}`);
} catch (ex) {}

module.exports = Object.assign({}, {
	API_URL: process.env.REACT_APP_API_URL,
	REDIRECT_URL: process.env.REACT_APP_REDIRECT_URL,
	CDN_URL: process.env.REACT_APP_CDN_URL,
	SSO_URL: process.env.REACT_APP_SAML_SSO_URL
}, config || {});
