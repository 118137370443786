import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { NavMenuLogo } from '../NavMenuLogo';
import { useResize } from 'react-browser-hooks';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import './styles.scss';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1
	},
	menuButton: {
		marginLeft: 'auto'
	}
}));

export function NavMenu(props) {
	const { userName } = props.userInfo || {};
	const history = useHistory();
	const { width } = useResize();
	const [drawer, setDrawer] = useState(false);

	const classes = useStyles();

	const toggleDrawer = () => event => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}
		setDrawer(!drawer);
	};

	useEffect(() => {
		if (width > 575) {
			setDrawer(false);
		}
	}, [width, setDrawer]);

	const goHome = () => history.push('/');

	return (
		<div className={classes.root}>
			<AppBar elevation={0} position='relative' className='nav-menu'>
				<Toolbar variant='dense' className='container'>
					<IconButton
						onClick={goHome}
						color='inherit'
						aria-label='menu'
						className='icon-button pl-0'
					>
						<NavMenuLogo />
					</IconButton>
					<Typography variant='h6' color='inherit' className='nav-bar-title'>
						New Heights Select Guaranteed Income Comparison Tool
					</Typography>
					<div className='nav-bar-right-options'>
						<span>Welcome {userName}</span>
					</div>
				</Toolbar>
			</AppBar>
		</div>
	);
}
