import React from 'react';

export function BlueArrowIcon({ className = '' }) {
	return (
		<div className={className}>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='90'
				height='59'
				viewBox='0 0 90 59'
			>
				<g>
					<g>
						<path
							fill='#0047bb'
							d='M67.5 0v9.077h7.2L42.75 41.3 27 25.415 0 52.646 6.3 59 27 38.123l15.75 15.885L81 15.43v7.261h9V0z'
						/>
					</g>
				</g>
			</svg>
		</div>
	);
}
