import React from 'react';
import { Bar } from './bar';
import { Chart } from './chart';

import './styles.scss';

export function BarChart({
	width = '100%',
	height = '100%',
	highestGroupValue,
	maxSizePercentage,
	sectionKey,
	config,
	isFIA
}) {
	const viewBox = {
		width: 1000,
		height: 117,
		sidebarWidth: 153,
		minimumBarWidth: 7,
		minimumBarWidthToFitLegend: 280,
		suitableBarWidthForLabel: 80
	};

	const BAR_PADDING = 5;
	const MAX_BAR_WIDTH_WITH_LEGEND_OUTSIDE = 530;

	const { bar, data } = config;

	const sidebarConfig = (({ sidebar, data }) => ({
		showVALegend: data.filter(datum => datum.type === 'VA').length !== 0,
		showFIALegend: data.filter(datum => datum.type === 'FIA').length !== 0,
		age: sidebar.age
	}))(config);

	const highestValue = highestGroupValue || ((data) => {
		const highestVal = data.reduce((prev, cur) => {
			const { value } = cur;
			return value > prev ? value : prev;
		}, 0);
		const nextHighestValue = (highestVal / 10) + highestVal;
		return nextHighestValue;
	})(data);

	const getRelativeValue = (value, highestValue) => {
		let relativeValue = 0;
		if (value > 0) {
			relativeValue = parseInt((value * (viewBox.width - viewBox.sidebarWidth)) / highestValue);
		} else {
			relativeValue = highestValue;
		}
		return (relativeValue < viewBox.minimumBarWidth
			? viewBox.minimumBarWidth
			: relativeValue) * (maxSizePercentage / 100);
	};

	const allLegendsInside = () => {
		const MAX_OFFSET_BETWEEN_LABEL_AND_LEGEND = 330;
		const x = viewBox.sidebarWidth;
		return data
			.reduce((prev, { value }) => {
				const barWidth = getRelativeValue(value, highestValue);
				const legendFits = (barWidth + x + BAR_PADDING) - (x + BAR_PADDING) > MAX_OFFSET_BETWEEN_LABEL_AND_LEGEND;
				return legendFits && prev;
			}, true);
	};

	const legendFits = allLegendsInside();

	return (
		<>
			<div className='results-bar-char-wrapper'>
				<Chart viewBox={viewBox} width={width} height={height} sidebarConfig={sidebarConfig} isFIA={isFIA}>
					{data.map((datum, index) => {
						const barId = `bar-${index}`;
						const barWidth = getRelativeValue(datum.value, highestValue);
						const forceLegendInside = datum.value === highestValue
							|| barWidth > MAX_BAR_WIDTH_WITH_LEGEND_OUTSIDE;
							
						return (
							<Bar
								key={barId}
								id={`${sectionKey}-${barId}`}
								height={bar.width}
								label={datum.label}
								color={datum.color}
								padding={BAR_PADDING}
								legend={datum.legend}
								checked={datum.checked}
								legendFits={legendFits || forceLegendInside}
								x={viewBox.sidebarWidth}
								isAlone={data.length === 1}
								y={index * (bar.width + bar.margin)}
								width={barWidth}
								suitableBarWidthForLabel={viewBox.suitableBarWidthForLabel}
								minimumBarWidthToFitLegend={viewBox.minimumBarWidthToFitLegend}
								isFIA={isFIA}
							/>
						);
					})}
				</Chart>
			</div >
		</>
	);
};

