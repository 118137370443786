import React, { useState, createRef } from 'react';
import { Formik, getIn } from 'formik';

import validationSchema from './validationSchema';

import { compose, waitFor } from '../../utils';
import { withMoment } from '../../utils/WithMoment';
import { withFooter } from '../../utils/WithFooter';
import { withCatalogs } from '../../utils/WithCatalogs';
import { withContext } from '../../contexts/appContext';
import { withNavigation } from '../../utils/WithNavigation';
import { withBreadcrumbs } from '../../utils/WithBreadcrumbs';
import { withProtection } from '../../utils/WithProtection';
import { OWNER_TYPES, CALCULATION_METHODS } from '../../../src/constants';

import {
	saveAnalyzerResults,
	saveAnalyzerPayloadOnLocalStorage
} from '../../api/AnalyzerService';

import {
	sanitizePayload,
	getInitialValues,
	mapToDropDown,
	getFormDate,
	getFormValueAsNumericPercentage,
	focusFirstError
} from './helpers';

import InputFormik from '../../components/shared/InputFormik';
import SelectFormik from '../../components/shared/SelectFormik';
import CustomNumberFormat from '../../components/shared/NumberFormat';
import CurrencyFormat from '../../components/shared/CurrencyFormat';
import PercentFormat from '../../components/shared/PercentFormat';
import { CustomBreadcrumbs } from '../../components/shared/Breadcrumbs';
import { AutoCompleteSelect } from '../../components/shared/AutoCompleteSelect';
import { YearsOfDeferralLabel } from './YearsOfDeferralLabel';
import ListItemCheckboxFormik from '../../components/shared/ListItemCheckboxFormik';

import './styles.scss';
import { SubmitButton } from './SubmitButton';

const routes = [
	{ path: '/', breadcrumb: 'home' },
	{ path: '/analyzer/info', breadcrumb: 'Info' }
];

export function AnalyzerInfo({
	navigateTo,
	states = [],
	ownerTypes = [],
	carriers = [],
	calculationMethods = [],
	momentHelper,
	breadcrumbs,
	setAnalyzerPayload,
	analyzerPayload
}) {
	const payload = sanitizePayload(analyzerPayload);

	const formRef = createRef();

	const initialValues = getInitialValues(payload);

	let approvedStates = mapToDropDown(states, 'text', 'code');
	if (approvedStates.length === 0) {
		approvedStates = [{ text: payload.State, code: payload.State }];
	}

	const carriersToRender = mapToDropDown(carriers, 'value', 'name');

	let calculationMethodsToRender = mapToDropDown(calculationMethods);
	if (calculationMethodsToRender.length === 0) {
		calculationMethodsToRender = [{ text: payload.CalculationMethod, code: payload.CalculationMethod }];
	}

	let ownerTypesToRender = mapToDropDown(ownerTypes);
	if (ownerTypesToRender.length === 0) {
		ownerTypesToRender = [{ text: payload.OwnerType, code: payload.OwnerType }];
	}

	const [daysInMonth, setDaysInMonth] = useState(momentHelper.getDaysInMonth());

	const [loading, setLoading] = useState(false);

	const updateDays = (month, year, day, setFieldValue) => {
		const newDaysInMonth = momentHelper.getDaysInMonth(month, year);
		setDaysInMonth(newDaysInMonth);
		if (day) {
			const dayInMonth = newDaysInMonth.filter(item => item.text === day)[0];
			if (!dayInMonth) {
				setFieldValue('Day', newDaysInMonth[newDaysInMonth.length - 1].text);
			}
		}
	};

	const mapFormValuesToRequestBody = formValues => {
		const contractPurchaseDate = getFormDate(
			formValues.Year,
			formValues.Month,
			formValues.Day
		);
		const requestBody = {
			CurrentAge: formValues['CurrentAge'] || '',
			LifeTimeIncomeStartAge: formValues['LifeTimeIncomeStartAge'] || 0,
			OwnerType: formValues['OwnerType'] || '',
			State: formValues['State'] || '',
			CurrentType: formValues['CurrentType'] || '',
			Carrier: formValues['Carrier'] || '',
			ProductName: formValues['ProductName'] || '',
			ContractPurchaseDate: contractPurchaseDate,
			ContractCashValue: formValues['ContractCashValue'] || 0,
			IncomeBenefitBase: formValues['IncomeBenefitBase'] || 0,
			CalculationMethod: formValues['CalculationMethod'] || '',
			IncomeBaseRollupRate: getFormValueAsNumericPercentage(
				formValues['IncomeBaseRollupRate']
			),
			TotalRollupPeriodTerm: formValues['TotalRollupPeriodTerm'] || 0,
			PayoutIncomeStart: getFormValueAsNumericPercentage(
				formValues['PayoutIncomeStart']
			),
			SurrenderValue: formValues['SurrenderValue'] || 0,
			NoRollUpEndDate: formValues['NoRollUp'] || false
		};
		if (requestBody.OwnerType === OWNER_TYPES.JOINT) {
			requestBody.JointInfo = {
				CurrentAge: formValues['JointCurrentAge'] || ''
			};
		}
		if (requestBody.CalculationMethod === CALCULATION_METHODS.SIMPLE) {
			requestBody.InitialIncomeBenefitBase =
				formValues['InitialIncomeBenefitBase'] || '';
		}
		return requestBody;
	};

	const onSubmit = formValues => {
		setLoading(true);
		const requestBody = mapFormValuesToRequestBody(formValues);
		// wait for animation
		const animationDuration = 1000;
		Promise.all([saveAnalyzerResults(requestBody), waitFor(animationDuration)])
			.then(([response]) => {
				setLoading(false);
				if (response !== null) {
					const analyzerPayload = { input: requestBody, output: response };
					setAnalyzerPayload(analyzerPayload);
					saveAnalyzerPayloadOnLocalStorage(analyzerPayload);
					navigateTo('/analyzer/info/results', {}, true);
				}
			})
			.catch(() => setLoading(false));
	};

	const handleOnClickSubmitForm = errors => {
		const form = formRef.current;
		focusFirstError(form, currentField => {
			return currentField.name && getIn(errors, currentField.name);
		});
	};

	const hasErrors = (fieldName, errors) => {
		return getIn(errors, fieldName);
	};

	return (
		<div className='container mb-5 analyzer-info-wrapper'>
			<div className='header-breadcrumbs-wrapper'>
				<CustomBreadcrumbs breadcrumbs={breadcrumbs} />
			</div>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{({
					values,
					handleSubmit,
					setFieldValue,
					dirty,
					errors,
					setFieldTouched
				}) => (
					<form onSubmit={handleSubmit} ref={formRef}>
						<React.Fragment>
							<div className='row col-12'>
								<h2 className='info-title'>
									Let's look at your client's current income guarantees
								</h2>
								<p>
									To see how much your clients could benefit from the retirement
									income and principal protection guarantees provided by New
									Heights Select and High Point 365 Select with Bonus, please provide some
									information about their current product.
								</p>
							</div>
							<div className='row col-12'>
								<label className='form-section-title'>Client</label>
							</div>
							<div className='bg-container row col-12'>
								<div className='row col-12'>
									<div className='col-lg-2 col-md-4 col-xs-12 mb-4 mt-1 pr-2 pl-2'>
										<SelectFormik
											name='State'
											label='State'
											options={approvedStates}
										/>
									</div>
									<div className='col-lg-3 col-md-4 col-xs-12 mb-4 mt-1 pr-2 pl-2'>
										<SelectFormik
											name='OwnerType'
											label='GLWB Type'
											options={ownerTypesToRender}
										/>
									</div>
								</div>
								<div className='row col-12'>
									<div className='col-lg-2 col-md-4 col-xs-12 mb-4 mt-1 pr-2 pl-2'>
										<InputFormik
											name='CurrentAge'
											label='Current Age'
											maxLength={2}
											numberFormatCustom={CustomNumberFormat}
											isErrorMessageDisplayed
										/>
									</div>
									<div className='col-lg-4 col-md-5 col-xs-12 mb-4 mt-1 pr-2 pl-2'>
										<InputFormik
											name='LifeTimeIncomeStartAge'
											label='Planned Lifetime Income Start Age'
											maxLength={2}
											hintText='Lifetime Income cannot be started until 1 year has elapsed since purchase date.'
											numberFormatCustom={CustomNumberFormat}
											isErrorMessageDisplayed
										/>
										{!hasErrors('LifeTimeIncomeStartAge', errors) && (
											<YearsOfDeferralLabel
												lifeTimeIncomeStartAge={values.LifeTimeIncomeStartAge}
												currentAge={values.CurrentAge}
											/>
										)}
									</div>
									{values.OwnerType === OWNER_TYPES.JOINT && (
										<div className='col-lg-2 col-md-4 col-xs-12 ml-lg-4 mb-4 mt-1 pr-2 pl-2'>
											<InputFormik
												name='JointCurrentAge'
												label='Joint Owner Age'
												numberFormatCustom={CustomNumberFormat}
												maxLength={2}
												isErrorMessageDisplayed
											/>
										</div>
									)}
								</div>
							</div>
							<div className='row col-12'>
								<label className='form-section-title'>
									Current Guaranteed Income Product
								</label>
								<p>
									The analysis is based on the information you provide, and you
									should refer to your client's contract and other account
									information to confirm the accuracy of these assumptions.
								</p>
							</div>
							<div className='bg-container row col-12'>
								<div className='row col-12'>
									<div className='col-lg-2 col-md-2 mb-2 mt-1 pr-2 pl-2'>
										<SelectFormik
											name='CurrentType'
											label='Type'
											options={[{ value: 'FIA', text: 'FIA' }, { value: 'VA', text: 'VA' }]}
										/>
									</div>
									<div className='col-lg-3 col-md-4 mb-2 mt-1 pr-2 pl-2'>
										<AutoCompleteSelect
											label='Carrier'
											name='Carrier'
											initialValue={values.Carrier}
											suggestionItems={carriersToRender}
											onSelection={selectedItem =>
												setFieldValue('Carrier', selectedItem)
											}
											itemToString={item =>
												item && item.hasOwnProperty('value')
													? item.text
													: values.Carrier
											}
											itemToKey={item => item.value}
											textFieldProps={{
												InputProps: {
													disableUnderline: true
												},
												inputProps: {
													maxLength: 100
												}
											}}
										/>
									</div>
									<div className='col-lg-5 col-md-6 mb-4 mt-1 pr-2 pl-2'>
										<InputFormik
											name='ProductName'
											label='Product Name'
											maxLength={100}
										/>
									</div>
								</div>
								<div className='row col-12'>
									<label className='form-section-subTitle col-lg-12'>
										Contract Purchase Date
									</label>
									<p className='col-lg-12'>
										For calculation purposes this tool assumes the New Heights Select
										contract has the same anniversary date as the current
										guaranteed income product contract being surrendered.
									</p>
									<div className='col-lg-3 col-md-4 mb-md-4 mb-4 mt-1 pr-2 pl-2'>
										<SelectFormik
											name='Month'
											label='Month'
											options={momentHelper.getMonths()}
											handleOnChange={event =>
												updateDays(
													event.target.value,
													values.Year,
													values.Day,
													setFieldValue
												)
											}
										/>
									</div>
									<div className='col-lg-2 col-md-4 mb-md-4 mb-4 mt-1 pr-2 pl-2'>
										<SelectFormik
											name='Day'
											label='Day'
											options={daysInMonth}
										/>
									</div>
									<div className='col-lg-2 col-md-4 mb-4 mt-1 pr-2 pl-2'>
										<SelectFormik
											name='Year'
											label='Year'
											options={momentHelper.getYearsOnRange(2000)}
											handleOnChange={event =>
												updateDays(
													values.Month,
													event.target.value,
													values.Day,
													setFieldValue
												)
											}
										/>
									</div>
								</div>
								<div className='row col-12'>
									<div className='col-lg-4 col-md-4 mb-md-4 mb-4 pr-xs-0 pr-3 pl-2'>
										<InputFormik
											name='ContractCashValue'
											label='Contract Value'
											numberFormatCustom={CurrencyFormat}
											maxLength={14}
											isErrorMessageDisplayed
										/>
									</div>
									<div className='col-lg-4 col-md-4 mb-md-4 mb-4 pr-xs-0 pr-3 pl-2'>
										<InputFormik
											name='SurrenderValue'
											label='Surrender Value'
											numberFormatCustom={CurrencyFormat}
											maxLength={14}
											isErrorMessageDisplayed
										/>
									</div>
									<div className='col-lg-4 col-md-4 mb-md-4 mb-4 pr-xs-0 pl-2'>
										<InputFormik
											name='IncomeBenefitBase'
											label='Income Benefit Base'
											numberFormatCustom={CurrencyFormat}
											maxLength={14}
											isErrorMessageDisplayed
										/>
									</div>
								</div>
								<div className='row col-12'>
									<div className='col-lg-4 col-md-5 mb-md-4 mb-4 pr-xs-0 pr-3 pl-2'>
										<InputFormik
											name='PayoutIncomeStart'
											label='Payout Factor at Income Start'
											numberFormatCustom={PercentFormat}
											maxLength={7}
											decimalscale={2}
											isErrorMessageDisplayed
										/>
									</div>
									<div className='col-lg-4 col-md-5 mb-md-4 mb-4 pr-xs-0 pl-2'>
										<InputFormik
											name='TotalRollupPeriodTerm'
											label='Total Rollup Period Term'
											numberFormatCustom={PercentFormat}
											maxLength={10}
											suffix=' year(s)'
											disabled={values.NoRollUp}
											isErrorMessageDisplayed
										/>
									</div>
									<div className='col-lg-4 col-md-2 mb-md-4 mb-4 pr-xs-0 pl-2 pr-md-0'>
										<ListItemCheckboxFormik
											name='NoRollUp'
											label='No Roll-up End Date'
											handleAfterOnChange={() => {
												setImmediate(() => {
													setFieldTouched('TotalRollupPeriodTerm', true);
												});
											}}
										/>
									</div>
								</div>
								<div className='row col-12'>
									<div className='col-lg-4 col-md-4 mb-md-4 mb-4 pr-xs-0 pr-3 pl-2'>
										<InputFormik
											name='IncomeBaseRollupRate'
											label='Income Base Rollup Rate'
											numberFormatCustom={PercentFormat}
											maxLength={7}
											decimalscale={2}
											isErrorMessageDisplayed
										/>
									</div>
									<div className='col-lg-4 col-md-4 mb-md-4 mb-4 pr-xs-0 pr-3 pl-2'>
										<SelectFormik
											name='CalculationMethod'
											label='Income Base Rollup'
											options={calculationMethodsToRender}
										/>
									</div>
									{values.CalculationMethod === CALCULATION_METHODS.SIMPLE && (
										<div className='col-lg-4 col-md-4 mb-md-4 mb-4 pr-xs-0 pr-3 pl-2'>
											<InputFormik
												name='InitialIncomeBenefitBase'
												label='Initial Income Benefit Base'
												hintText='The Initial Income Base is used to determine what the simple roll-up rate is based on. Typically, this is based on the initial premium unless a premium bonus or income base bonus were applied at issue.'
												numberFormatCustom={CurrencyFormat}
												maxLength={14}
												isErrorMessageDisplayed
											/>
										</div>
									)}
								</div>
							</div>
						</React.Fragment>
						<div className='col-12 mt-4 pl-0 pb-3'>
							<p>
								We collect the information provided through the use of this
								tool. The information may be stored and shared with our business
								partners in order to improve the product analysis and future
								tool outcomes.
							</p>
						</div>
						<div className='col-12 pl-0'>
							<div>
								<SubmitButton
									dirty={dirty}
									errors={errors}
									payload={payload}
									loading={loading}
									handleOnClickSubmitForm={handleOnClickSubmitForm}
								/>
							</div>
						</div>
					</form>
				)}
			</Formik>
		</div>
	);
}

export default compose(
	withProtection(),
	withFooter('common-footer'),
	withNavigation(),
	withCatalogs(['states', 'ownerTypes', 'carriers', 'calculationMethods']),
	withMoment(),
	withBreadcrumbs(routes),
	withContext()
)(AnalyzerInfo);
