import React from 'react';

export default function CheckMarkBackground() {
	return (
		<svg width='153' height='149' viewBox='0 0 153 149'>
			<g>
				<g clipPath='url(#clip-A511B4F3-FDAB-44F6-9239-9A078BB9E914)' opacity='.15'><path fill='#fff' d='M36.017 125.57c-.957.953-1.754 1.43-2.392 1.43-.638 0-1.435-.477-2.392-1.43-.32-.318-12.918-12.392-37.798-36.224l-.957-.477C-7.84 88.551-8 88.075-8 87.44c0-.953.16-1.589.478-1.906L4.918 73.14c.957-.953 1.595-1.43 1.914-1.43.957 0 1.914.477 2.87 1.43.32.318 7.496 7.15 21.53 20.496l53.109-68.16c.318-.317.797-.476 1.435-.476.957 0 1.595.159 1.914.477l14.832 11.439c.319.318.478.953.478 1.906 0 .636-.16 1.113-.478 1.43zM-60 74.5c0 29.478 10.38 54.597 31.142 75.358C-8.097 170.62 17.022 181 46.5 181s54.597-10.38 75.358-31.142C142.62 129.097 153 103.978 153 74.5S142.62 19.903 121.858-.858C101.097-21.62 75.978-32 46.5-32S-8.176-21.54-29.096-.62C-49.699 19.981-60 45.021-60 74.5z' />
				</g>
			</g>
		</svg>
	);
}