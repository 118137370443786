import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { ClickAwayListener } from '@material-ui/core';

import {
	getNumbersAndDecimalsOnly,
	splitDecimal,
	limitToScale,
	applyThousandSeparator
} from '../../../utils';

const PercentFormat = ({
	decimalSeparator,
	decimalscale,
	fixedDecimalScale,
	inputRef,
	onChange,
	prefix,
	returntypevalue,
	suffix,
	thousandSeparator,
	allowNegative,
	...rest
}) => {
	const [showSuffix, setShowSuffix] = useState(false);

	// values : {formattedValue: "34.40%", value: "34.40", floatValue: 34.4}
	const handleOnChange = values => {
		let value = null;
		switch (returntypevalue) {
		case 'formattedValue':
			value =
					values.formattedValue === undefined ? '' : values.formattedValue;
			break;
		case 'floatValue':
			value = values.floatValue === undefined ? '' : values.floatValue;
			break;
		case 'value':
			value = values.value === undefined ? '' : values.value;
			break;
		default:
			value = values;
			break;
		}
		return onChange({
			currentTarget: {
				value
			},
			target: {
				value
			}
		});
	};

	const handleFormat = fv => {
		let formattedValue = fv;
		// if decimal scale is zero remove decimal and number after decimalSeparator
		if (decimalSeparator && decimalscale === 0) {
			[formattedValue] = formattedValue.split(decimalSeparator);
		}
		let { beforeDecimal, afterDecimal, addNegation } = splitDecimal(
			formattedValue,
			false
		);

		const hasDecimalSeparator =
			formattedValue.indexOf('.') !== -1 ||
			(decimalscale && fixedDecimalScale && !!afterDecimal);

		if (decimalscale !== undefined && afterDecimal !== '')
			afterDecimal = limitToScale(
				afterDecimal,
				decimalscale,
				fixedDecimalScale
			);
		if (thousandSeparator) {
			beforeDecimal = applyThousandSeparator(beforeDecimal, thousandSeparator);
		}
		if (prefix) beforeDecimal = prefix + beforeDecimal;
		if (suffix) afterDecimal += suffix; // restore negation sign
		if (addNegation) beforeDecimal = `-${beforeDecimal}`;
		return (
			beforeDecimal +
			((hasDecimalSeparator && decimalSeparator) || '') +
			afterDecimal
		);
	};

	const handleRemoveFormatting = val => getNumbersAndDecimalsOnly(val);

	return (
		<ClickAwayListener onClickAway={() => setShowSuffix(false)}>
			<NumberFormat
				{...rest}
				getInputRef={inputRef}
				onValueChange={values => {
					handleOnChange(values);
				}}
				thousandSeparator
				suffix={suffix}
				spellCheck={false}
				inputMode='decimal'
				allowNegative={allowNegative}
				decimalScale={decimalscale}
				format={handleFormat}
				removeFormatting={handleRemoveFormatting}
				allowEmptyFormatting={showSuffix}
				onClick={() => setShowSuffix(true)}
				onFocus={() => {
					rest.onFocus();
					setShowSuffix(true);
				}}
				onBlurCapture={() => setShowSuffix(false)}
			/>
		</ClickAwayListener>
	);
};

PercentFormat.propTypes = {
	decimalSeparator: PropTypes.string,
	decimalscale: PropTypes.number,
	fixedDecimalScale: PropTypes.bool,
	inputRef: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	prefix: PropTypes.string,
	returntypevalue: PropTypes.string,
	suffix: PropTypes.string,
	thousandSeparator: PropTypes.string,
	allowNegative: PropTypes.bool
};

PercentFormat.defaultProps = {
	decimalSeparator: '.',
	decimalscale: 2,
	fixedDecimalScale: true,
	returntypevalue: 'floatValue',
	suffix: '%',
	thousandSeparator: ',',
	allowNegative: false
};

export default PercentFormat;
