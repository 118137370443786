import React from 'react';

import { HomeFooter } from '../HomeFooter';

import ResultFooter, { CommonFooter } from '../CommonFooter';

const FootersCollection = new Map([
	['home-footer', () => <HomeFooter />],
	['common-footer', () => <CommonFooter />],
	['result-footer', () => <ResultFooter />],
	['va-footer', () => null]
]);

function resolveFooterToRender(footerName) {
	const renderFooterFn = FootersCollection.get(footerName);
	return renderFooterFn && renderFooterFn();
}

export function SmartFooter({ footerName }) {
	return (
		<div className={`layout-${footerName}`}>
			<div className={`container content-${footerName}`}>
				<div>{resolveFooterToRender(footerName)}</div>
			</div>
		</div>
	);
}
