import { createMuiTheme } from '@material-ui/core';
import createPalette from '@material-ui/core/styles/createPalette';

const defaultTheme = createMuiTheme();

const palette = createPalette({
	white: '#ffffff',
	funBlue: '#004d94',
	celeste: '#c9cac8',
	mineShaft: '#333333',
	desertStorm: '#F2F2F0',
	abbey: '#4D4F53',
	cerulean: '#05add3',
	chambrayBlue: '#0067c5',
	titleBlue: '#003b5c',
	calypso: '#285C75',
	gray80: '#cccccc',
	popperBorder: '#00000033',
	downy: '#6eceb2',
	deepSea: '#008574',
	cobalt: '#0047bb',
	bunting: '#141b4d',
	neptune: '#72b0bd',
	darkCelurian: '#005670'
});

const globalFont = 'Gotham-Book';
export default createMuiTheme({
	palette,
	typography: {
		button: {
			textTransform: 'none'
		}
	},
	overrides: {
		MuiFormControl: {
			root: {
				display: 'inline-block'
			}
		},
		MuiFilledInput: {
			root: {
				backgroundColor: 'transparent',
				'&$error': {
					border: '1px solid red'
				},
				'&:hover': {
					backgroundColor: 'transparent'
				},
				'&$focused': {
					backgroundColor: 'transparent'
				},
				'@media (hover: none)': {
					'&:hover': {
						backgroundColor: 'transparent !important'
					}
				}
			},
			input: {
				paddingBottom: '0.35714em',
				paddingTop: '1.5em'
			},
			adornedEnd: {
				paddingRight: 0,
				'@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)': {
					paddingRight: 12
				}
			}
		},
		MuiInputBase: {
			root: {
				border: '1px solid #d9d9d9',
				borderRadius: '4px',
				lineHeight: 1.18,
				width: '100%',
				fontFamily: globalFont,
				'&$error': {
					border: '1px solid red'
				},
				'&$focused': {
					border: '1px solid #007ec3'
				},
				'& .icons-container': {
					top: 'calc(50% - 4px)',
					right: '0',
					color: 'rgba(0, 0, 0, 0.54)',
					pointerEvents: 'none',
					position: 'absolute',
					'& .arrow': {
						display: 'inline',
						borderLeft: '1px solid #d9d9d9',
						paddingTop: '10px',
						marginLeft: '5px'
					}
				}
			},
			input: {
				backgroundColor: 'white',
				borderRadius: '4px',
				color: '#767676',
				fontSize: '15px',
				'&:disabled': {
					backgroundColor: '#FBFBFA',
					borderColor: '#EEEEED',
					color: '#CBCBCA'
				}
			}
		},
		MuiInputLabel: {
			root: {
				color: '#737373;',
				fontSize: '14px',
				width: '89%',
				letterSpacing: '-0.2px',
				fontFamily: globalFont,
				[defaultTheme.breakpoints.down('xs')]: {
					width: '84%',
					lineHeight: '12px',
					fontSize: 14
				},
				'&$focused': {
					color:  `${palette.cobalt}`,
					lineHeight: '0.9',
					width: '100%',
					transform: 'translate(12px, 8px) scale(0.75) !important'
				},
				'&$shrink': {
					color: `${palette.cobalt}`,
					lineHeight: '0.9',
					width: '100%'
				},
				'&$error': {
					color: 'red'
				},
				'&$disabled': {
					color: '#CBCBCA'
				},
				'&$filled': {
					transform: 'translate(12px, 18px) scale(1)',
					'&&.MuiInputLabel-shrink': {
						transform: 'translate(12px, 8px) scale(0.75)'
					}
				}
			}
		},
		MuiSelect: {
			select: {
				borderRadius: '4px',
				'&$filled': {
					backgroundColor: 'white',
					borderRadius: '4px'
				},
				'&$disabled': {
					color: '#b6b6b6 !important'
				},
				'& .option-changed': {
					outline: 'none',
					borderColor: '#9ecaed',
					boxShadow: '0 0 10px #9ecaed'
				}
			}
		},
		MuiSvgIcon: {
			root: {
				'&.arrow-icon': {
					position: 'relative',
					top: '-6px'
				}
			}
		},
		MuiButton: {
			root: {
				height: '40px',
				backgroundColor: palette.deepSea,
				color: palette.white,
				borderRadius: '4px',
				border: `1px solid ${palette.deepSea}`,
				fontFamily: 'Gotham-Medium',
				fontSize: '15px',
				minWidth: '200px',
				[defaultTheme.breakpoints.down('xs')]: {
					width: '100%',
					marginBottom: '10px !important'
				},
				'&:hover': {
					backgroundColor: ` ${palette.darkCelurian}`,
					border: `1px solid ${palette.darkCelurian}`
				},
				'&:focus': {
					outline: '0',
					backgroundColor: palette.deepSea,
					color: palette.white,
					border: `1px solid ${palette.deepSea}`
				},
				'&:disabled': {
					opacity: 0.5,
					border: 'none',
					backgroundColor: `${palette.neptune} !important `,
					color: palette.bunting
				},
				'&.cancel-button': {
					border: `1px solid ${palette.cobalt} !important`,
					backgroundColor: palette.white,
					color: palette.cobalt
				},
				'&.cancel-button:hover': {
					backgroundColor: palette.cobalt,
					color: palette.white
				},
				'&.view-results-button': {
					marginBottom: '90px'
				},
				'&.edit-button': {
					marginBottom: '90px',
					'&-return' : {
						border: `1px solid ${palette.cobalt} !important`,
						color: palette.cobalt,
						'&:hover': {
							backgroundColor: ` ${palette.cobalt}`,
							border: `1px solid ${palette.cobalt}`
						},
					}
				},
				'&.run-new': {
					padding: '6px 40px'
				},
				'&.get-started-button': {
					backgroundColor: palette.chambrayBlue,
					color: palette.white,
					border: `1px solid ${palette.chambrayBlue}`,
					fontFamily: 'Gotham-Medium',
					'&:hover': {
						backgroundColor: ` ${palette.funBlue}`,
						border: `1px solid ${palette.funBlue}`
					},
				}
			}
		},
		MuiIconButton: {
			root: {
				'&:focus': {
					outline: '0'
				},
				'&.icon-button:hover': {
					backgroundColor: 'rgba(0, 0, 0, 0.0)'
				},
				padding: '11px 10px'
			}
		},
		MuiAppBar: {
			root: {
				zIndex: 1301
			}
		},
		MuiTooltip: {
			tooltip: {
				top: '5px',
				borderRadius: '6px',
				color: palette.mineShaft,
				fontSize: '12px',
				padding: '9px 14px',
				marginTop: '15px',
				backgroundColor: palette.white,
				border: `1px solid ${palette.popperBorder}`,
				fontFamily: globalFont,
				maxWidth: '230px'
			},
			popperArrow: {
				'& .MuiTooltip-arrow': {
					border: '1px solid',
				},
				'&[x-placement*="left"]': {
					'& .MuiTooltip-arrow': {
						borderColor: `transparent transparent transparent ${palette.popperBorder}`,
						borderWidth: '1em 0 1em 1em',
						'&:before': {
							marginTop: '-6px',
							marginLeft: '-7px',
						}
					}
				},
				'&[x-placement*="right"]': {
					'& .MuiTooltip-arrow': {
						borderColor: `transparent ${palette.popperBorder} transparent transparent`,
						borderWidth: '1em 1em 1em 0',
						'&:before': {
							marginTop: '-6px',
							marginLeft: '1px',
						}
					}
				},
				'&[x-placement*="top"]': {
					'& .MuiTooltip-arrow': {
						borderColor: `${palette.popperBorder} transparent transparent transparent`,
						borderWidth: '1em 1em 0 1em',
						'&:before': {
							marginTop: '-7px',
							marginLeft: '-6px',
						}
					}
				},
				'&[x-placement*="bottom"]': {
					'& .MuiTooltip-arrow': {
						borderColor: `transparent transparent ${palette.popperBorder} transparent`,
						borderWidth: '0 1em 1em 1em',
						'&:before': {
							marginTop: '1px',
							marginLeft: '-6px',
						}
					}
				}
			},
			arrow: {
				color: palette.white
			}
		},
		MuiListItem: {
			root: {
				'&.checkbox-container': {
					padding: 0
				}
			}
		},
		MuiRadio: {
			root: {
				color: palette.cobalt
			}
		}
	}
});
