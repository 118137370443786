import React from 'react';

import moment from 'moment';

const MomentHelper = () => {
	function getMonths() {
		return moment.months().map((name, i) => {
			return {
				value: i + 1,
				text: name
			};
		});
	}

	function getDaysInMonth(month = moment().month() + 1, year = moment().year()) {
		const lastDay = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();
		return Array(lastDay)
			.fill()
			.map((_, i) => {
				const value = i + 1;
				return {
					value,
					text: String(value)
				};
			});
	}

	function getYearsOnRange(from, to = moment().year()) {
		return [
			{ value: from, text: String(from) },
			...Array(to - from)
				.fill()
				.reduce(acc => {
					const lastYear = acc[acc.length - 1] || { value: from };
					const nextYear = lastYear.value + 1;
					const newItem = {
						value: nextYear,
						text: String(nextYear)
					};
					acc.push(newItem);
					return acc;
				}, [])
		];
	}

	return {
		getMonths,
		getDaysInMonth,
		getYearsOnRange
	};
};

export const withMoment = () => ScreenComponent => {
	const momentHelper = MomentHelper();
	return props => {
		return <ScreenComponent {...props} momentHelper={momentHelper} />;
	};
};
