import { doRequest } from '../utils/ApiHelper';

const ANALYZER_PAYLOAD_KEY = 'VA_EXCHANGE_ANALYZER_RESULT_VALUE';

export async function saveAnalyzerResults(requestBody) {
	try {
		const response = await doRequest({
			url: 'api/v1/AnalyzerResults/ViewResults',
			method: 'POST',
			data: requestBody
		});
		return response;
	} catch (err) {
		console.error('Error trying to save the analyzer results', err);
		return null;
	}
}

export const getAnalyzerPayload = () => {
	const analyzerResult = localStorage.getItem(ANALYZER_PAYLOAD_KEY);
	return Promise.resolve(analyzerResult === null ? null : JSON.parse(analyzerResult));
};

export const saveAnalyzerPayloadOnLocalStorage = value => {
	new Promise((resolve, reject) => {
		try {
			resolve(localStorage.setItem(ANALYZER_PAYLOAD_KEY, JSON.stringify(value)));
		} catch (error) {
			reject(error);
		}
	});
};