import React from 'react';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { getStartingPositionInX } from './helper';

const BarTooltip = withStyles({
	tooltip: {
		fontSize: 12, 
		padding: '6px 7px',
	}
})(Tooltip);

export function GroupedBar({
	index,
	label,
	value,
	color,
	barWidth,
	dataLength,
	isSecondGroup,
	normalizeValue,
	maxWidthPerGroup,
	highestGroupValue,
	maxSizePercentage
}) {
	const barPadding = 5;
	let positionInX = getStartingPositionInX(dataLength, maxWidthPerGroup, barWidth, index, barPadding);

	if (isSecondGroup) {
		positionInX += maxWidthPerGroup;
	}

	const normalizedValue = normalizeValue(value, highestGroupValue, 0, maxSizePercentage);
	const key = isSecondGroup ? `gb-${index}-f` : `gb-${index}-s`;

	return (
		<BarTooltip
			key={key}
			arrow
			tabIndex='-1'
			open={true}
			placement='top'
			title={label}
			disableFocusListener
			leaveTouchDelay={5000}
			PopperProps={{
				modifiers: {
					flip: {
						enabled: false
					}
				}
			}}
		>
			<g>
				<path
					fill={color}
					d={`M-7 ${positionInX}h${normalizedValue}a4 4 0 0 1 4 4v44a4 4 0 0 1-4 4H-7z`}
				/>
				<path
					fill='url(#4gqub)'
					d={`M-7 ${positionInX}h${normalizedValue}a4 4 0 0 1 4 4v44a4 4 0 0 1-4 4H-7z`}
				/>
			</g>

		</BarTooltip>
	);
}