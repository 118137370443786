import React from 'react';

export const EmptyCheckBoxSvg = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='18'
		height='18'
		viewBox='0 0 18 18'
	>
		<defs>
			<clipPath id='bmk8a'>
				<path
					xmlns='http://www.w3.org/2000/svg'
					fill='#fff'
					d='M0 4a4 4 0 0 1 4-4h10a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z'
				/>
			</clipPath>
		</defs>
		<g>
			<g>
				<path
					xmlns='http://www.w3.org/2000/svg'
					fill='#fff'
					d='M0 4a4 4 0 0 1 4-4h10a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z'
				/>
				<path
					xmlns='http://www.w3.org/2000/svg'
					fill='none'
					stroke='#d9d9d9'
					strokeMiterlimit='50'
					strokeWidth='2'
					d='M0 4a4 4 0 0 1 4-4h10a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z'
					clipPath='url("#bmk8a")'
				/>
			</g>
		</g>
	</svg>
);

export const FilledCheckBoxSvg = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='18'
		height='18'
		viewBox='0 0 18 18'
	>
		<defs>
			<clipPath id='jy0ua'>
				<path
					fill='#fff'
					d='M0 4a4 4 0 0 1 4-4h10a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z'
				/>
			</clipPath>
		</defs>
		<g>
			<g>
				<path
					fill='#1c57a5'
					d='M0 4a4 4 0 0 1 4-4h10a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z'
				/>
				<path
					fill='none'
					stroke='#1c57a5'
					strokeMiterlimit='50'
					strokeWidth='2'
					d='M0 4a4 4 0 0 1 4-4h10a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z'
					clipPath='url("#jy0ua")'
				/>
			</g>
			<g>
				<path
					fill='#fff'
					d='M7.248 11.546l6.174-6.196.826.827-7 7.009L4 9.934l.812-.827z'
				/>
			</g>
		</g>
	</svg>
);

export const PartialCheckBoxSvg = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='18'
		height='18'
		viewBox='0 0 18 18'
	>
		<defs>
			<clipPath id='za2ca'>
				<path
					fill='#fff'
					d='M0 4a4 4 0 0 1 4-4h10a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z'
				/>
			</clipPath>
		</defs>
		<g>
			<g>
				<path
					fill='#1c57a5'
					d='M0 4a4 4 0 0 1 4-4h10a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z'
				/>
				<path
					fill='none'
					stroke='#1c57a5'
					strokeMiterlimit='50'
					strokeWidth='2'
					d='M0 4a4 4 0 0 1 4-4h10a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z'
					clipPath='url("#za2ca")'
				/>
			</g>
			<g>
				<path fill='#fff' d='M4 8h10v2H4z' />
			</g>
		</g>
	</svg>
);
