import React from 'react';
import PropTypes from 'prop-types';
import CheckMarkBackground from '../CheckMarkBackground';

import './styles.scss';

export function HeaderCardsComparison({
	variableAnnuityCard: { vaChecked, vaText },
	fixedIndexedAnnuityCard: { fiaChecked, fiaText },
	isFIA
}) {
	return (
		<div className='header-cards-comparison-wrapper'>
			<div className='row justify-content-md-center row-content'>
				<div className={isFIA ? 'col-md-12' : 'col-md-6' }>
					<div
						className={isFIA ? 'first-card fia-label' : 'first-card'}
						style={{ backgroundColor: vaChecked ? '#32b30f' : '#b0b0b0' }}
					>
						{vaText}
						<CheckMarkBackground />
					</div>
				</div>
				{!isFIA && (
					<>
						<div className='middle-and'>
							<span>&amp;</span>
						</div>
						<div className='col-md-6'>
							<div
								className='second-card'
								style={{ backgroundColor: fiaChecked ? '#32b30f' : '#b0b0b0' }}
							>
								{fiaText}
								<CheckMarkBackground />
							</div>
						</div>
					</>
				)}
			</div>
			<div className='col-12 mt-4 pl-0'>
				<p>
					New Heights Select and High Point 365 Select with Bonus offer 100% protection for
					your client's purchase payment and any credited earnings, plus a
					source of guaranteed retirement income guaranteed to grow.<sup>1</sup>{' '}
					This analysis assumes that 100% of the Cash Value you entered can be
					used to fund New Heights Select and High Point 365 Select with Bonus.
				</p>
				<p>
					Fixed indexed annuities protect 100% of clients' principal and any
					credited interest from market downturns. {isFIA ? '' : `Many Variable Annuities do
					not automatically provide protection for principal or earnings except
					as a death benefit.`}
				</p>
			</div>
		</div>
	);
}

HeaderCardsComparison.propTypes = {
	variableAnnuityCard: PropTypes.shape({
		vaChecked: PropTypes.bool,
		vaText: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
	}),
	fixedIndexedAnnuityCard: PropTypes.shape({
		fiaChecked: PropTypes.bool,
		fiaText: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
	})
};
