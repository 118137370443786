import FontFaceObserver from 'fontfaceobserver';

const waitForFonts = (names = []) => {
	return Promise.all(
		names.map(name => {
			return new FontFaceObserver(name).load().catch(err => {
				console.warn(`Error loading font ${name}`, err);
			});
		})
	);
};

export const asBoolean = value => value === 'true';

export const compose = (...fns) => arg => {
	return fns.reduce((prev, fn) => {
		return fn(prev);
	}, arg);
};

export const removeParamFromURL = (key, sourceURL) => {
	let rtn = sourceURL.split('?')[0];
	let param;
	let paramsArr = [];

	const queryString =
		sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';
	if (queryString !== '') {
		paramsArr = queryString.split('&');
		for (let i = paramsArr.length - 1; i >= 0; i -= 1) {
			param = paramsArr[i].split('=')[0];
			if (param === key) {
				paramsArr.splice(i, 1);
			}
		}
		if (paramsArr.length > 0) {
			rtn = `${rtn}?${paramsArr.join('&')}`;
		}
	}
	return rtn;
};

export async function tryLoadFonts() {
	try {
		await waitForFonts([
			'Gotham-Book',
			'Gotham-Bold',
			'Gotham-Medium',
			'Gotham-Light',
			'Chronicle Text G1 Italic',
			'ProximaNova-Regular',
			'Lato'
		]);
	} catch (err) {
		console.warn('Could not load fonts', err);
	}
}

export const bindEvent = (element, eventName, eventHandler) => {
	if (element.addEventListener) {
		element.addEventListener(eventName, eventHandler, false);
	} else if (element.attachEvent) {
		element.attachEvent(`on${eventName}`, eventHandler);
	}
};

export const removeEvent = (element, eventName, eventHandler) => {
	if (element.removeEventListener) {
		element.removeEventListener(eventName, eventHandler, false);
	} else if (element.detachEvent) {
		element.detachEvent(`on${eventName}`, eventHandler);
	}
};

export const inIframe = () => {
	try {
		return window.self !== window.top;
	} catch (e) {
		return true;
	}
};

export const waitFor = time => {
	return new Promise(resolve => {
		setTimeout(resolve, time);
	});
};

export const splitDecimal = (numStrParam, allowNegative = true) => {
	const hasNagation = numStrParam[0] === '-';
	const addNegation = hasNagation && allowNegative;
	const numStr = numStrParam.replace('-', '');

	const parts = numStr.split('.');
	const beforeDecimal = parts[0];
	const afterDecimal = parts[1] || '';

	return {
		beforeDecimal,
		afterDecimal,
		hasNagation,
		addNegation
	};
};

/**
 * Get numbers and decimals from a formatted value, otherwise returns null
 * @param {string} value Formatted value
 */
export const getNumbersAndDecimalsOnly = value =>
	typeof value === 'string' ? (value.match(/\d*[.]?/g) || []).join('') : null;

export const limitToScale = (numStr, scale, fixedDecimalScale) => {
	let str = '';
	const filler = fixedDecimalScale ? '0' : '';

	for (let i = 0; i <= scale - 1; i += 1) {
		str += numStr[i] || filler;
	}
	// if (str === '00') {
	//   return '';
	// }
	return str;
};

export const applyThousandSeparator = (str, thousandSeparator) => {
	const thousandsGroupRegex = /(\d)(?=(\d{3})+(?!\d))/g;
	let index = str.search(/[1-9]/);
	index = index === -1 ? str.length : index;
	return (
		str.substring(0, index) +
		str
			.substring(index, str.length)
			.replace(thousandsGroupRegex, `$1${thousandSeparator}`)
	);
};
