import { setupValues as skeletonConfig } from './setupValues';
import { ANALYZER_RESULT_KEYS } from '../../constants';
import { toCurrency, toPercentage } from '../../utils/UtilHelper';

const getChartDataFormatted = ({
	legend = '',
	value = 0,
	color = '',
	type = '',
	label = '',
	checked = false
}) => ({
	legend: legend,
	value: value,
	color: color,
	type: type,
	checked: checked,
	label: label
});

const types = {
	VA: {
		type: 'VA',
		color: '#333333'
	},
	FIA: {
		type: 'FIA',
		color: '#0047bb',
		colorMarked: '#32cb11'
	}
};

const getHighestGlobalValue = analyzerResults => {
	const {
		guaranteedIncome,
		contractCashValue,
		incomeBenefitBase,
		payoutPercentage
	} = analyzerResults;
	const incomeBenefitBaseValues = incomeBenefitBase.reduce(
		(prev, curr) =>
			prev.concat([curr.fixingIndexAnnuity, curr.variableAnnuity]),
		[]
	);
	return Math.max(
		...[
			guaranteedIncome.fixingIndexAnnuity,
			guaranteedIncome.variableAnnuity,
			contractCashValue.fixingIndexAnnuity,
			contractCashValue.variableAnnuity,
			payoutPercentage.fixingIndexAnnuity,
			payoutPercentage.variableAnnuity,
			...incomeBenefitBaseValues
		]
	);
};

export const getSimilarAmountsConfig = analyzerResults => {
	const { VA, FIA } = types;

	const configs = Object.keys(skeletonConfig).map(configKey => {
		let config = skeletonConfig[configKey];
		const analyzerResult = analyzerResults[configKey];

		const { guaranteedIncome, contractCashValue } = ANALYZER_RESULT_KEYS;

		if (configKey === guaranteedIncome) {
			config.chartConfig.sidebar.age = analyzerResult.age;

			config.chartConfig.data[1] = getChartDataFormatted({
				type: VA.type,
				color: VA.color,
				value: analyzerResult.variableAnnuity,
				label: toCurrency(analyzerResult.variableAnnuity)
			});
			config.chartConfig.data[0] = getChartDataFormatted({
				type: FIA.type,
				color: FIA.color,
				value: analyzerResult.fixingIndexAnnuity,
				label: toCurrency(analyzerResult.fixingIndexAnnuity)
			});
		} else if (configKey === contractCashValue) {
			config.chartConfig.sidebar.age = analyzerResult.age;

			config.chartConfig.data[0] = getChartDataFormatted({
				legend: 'Protected from Market Losses',
				type: FIA.type,
				color: FIA.colorMarked,
				checked: true,
				value: analyzerResult.fixingIndexAnnuity,
				label: toCurrency(analyzerResult.fixingIndexAnnuity)
			});
			config.chartConfig.data[1] = getChartDataFormatted({
				legend: 'Not Protected from Market Losses',
				type: VA.type,
				color: VA.color,
				value: analyzerResult.variableAnnuity,
				label: toCurrency(analyzerResult.variableAnnuity)
			});
		} else {
			config = null;
		}
		config &&
			(config.highestGlobalValue = getHighestGlobalValue(analyzerResults));
		return config;
	});
	return configs.filter(config => Boolean(config));
};

export const getMoreGuaranteedIncomeConfig = (analyzerResults, isFIA) => {
	const { VA, FIA } = types;

	const configs = Object.keys(skeletonConfig).map(configKey => {
		const config = skeletonConfig[configKey];
		const analyzerResult = analyzerResults[configKey];

		const {
			guaranteedIncome,
			contractCashValue,
			incomeBenefitBase
		} = ANALYZER_RESULT_KEYS;

		if (configKey === guaranteedIncome) {
			config.chartConfig.sidebar.age = analyzerResult.age;

			config.chartConfig.data[1] = getChartDataFormatted({
				type: VA.type,
				color: VA.color,
				value: analyzerResult.variableAnnuity,
				label: toCurrency(analyzerResult.variableAnnuity)
			});
			config.chartConfig.data[0] = getChartDataFormatted({
				type: FIA.type,
				color: FIA.color,
				value: analyzerResult.fixingIndexAnnuity,
				label: toCurrency(analyzerResult.fixingIndexAnnuity)
			});
		} else if (configKey === contractCashValue) {
			config.chartConfig.sidebar.age = analyzerResult.age;

			config.chartConfig.data[1] = getChartDataFormatted({
				legend: isFIA ? 'Protected from Market Losses' : 'Not Protected from Market Losses',
				type: VA.type,
				color: VA.color,
				value: analyzerResult.variableAnnuity,
				label: toCurrency(analyzerResult.variableAnnuity)
			});
			config.chartConfig.data[0] = getChartDataFormatted({
				legend: 'Protected from Market Losses',
				type: FIA.type,
				color: FIA.color,
				value: analyzerResult.fixingIndexAnnuity,
				label: toCurrency(analyzerResult.fixingIndexAnnuity)
			});
		} else if (configKey === incomeBenefitBase) {
			const [firstAnalyzerResult, secondAnalyzerResult] = analyzerResult;
			config.chartConfig[1].sidebar.age = firstAnalyzerResult.age;

			config.chartConfig[1].data[1] = getChartDataFormatted({
				type: VA.type,
				color: VA.color,
				value: firstAnalyzerResult.variableAnnuity,
				label: toCurrency(firstAnalyzerResult.variableAnnuity)
			});
			config.chartConfig[1].data[0] = getChartDataFormatted({
				type: FIA.type,
				color: FIA.color,
				value: firstAnalyzerResult.fixingIndexAnnuity,
				label: toCurrency(firstAnalyzerResult.fixingIndexAnnuity)
			});

			config.chartConfig[0].sidebar.age = secondAnalyzerResult.age;

			config.chartConfig[0].data[1] = getChartDataFormatted({
				type: FIA.type,
				color: VA.color,
				value: secondAnalyzerResult.variableAnnuity,
				label: toCurrency(secondAnalyzerResult.variableAnnuity)
			});
			config.chartConfig[0].data[0] = getChartDataFormatted({
				type: VA.type,
				color: FIA.color,
				value: secondAnalyzerResult.fixingIndexAnnuity,
				label: toCurrency(secondAnalyzerResult.fixingIndexAnnuity)
			});
		} else {
			config.chartConfig.sidebar.age = analyzerResult.age;

			config.chartConfig.data[1] = getChartDataFormatted({
				type: VA.type,
				color: VA.color,
				value: analyzerResult.variableAnnuity,
				label: toPercentage(analyzerResult.variableAnnuity)
			});
			config.chartConfig.data[0] = getChartDataFormatted({
				type: FIA.type,
				color: FIA.color,
				value: analyzerResult.fixingIndexAnnuity,
				label: toPercentage(analyzerResult.fixingIndexAnnuity)
			});
		}
		config.highestGlobalValue = getHighestGlobalValue(analyzerResults);
		return config;
	});
	return configs;
};

export const getDoingGreatConfig = analyzerResults => {
	const type = 'VA';
	const color = '#1c57a5';
	const configs = Object.keys(skeletonConfig).map(configKey => {
		const config = skeletonConfig[configKey];
		const analyzerResult = analyzerResults[configKey];

		const {
			guaranteedIncome,
			contractCashValue,
			incomeBenefitBase,
			payoutPercentage
		} = ANALYZER_RESULT_KEYS;

		if (configKey === guaranteedIncome) {
			config.chartConfig.sidebar.age = analyzerResult.age;
			config.chartConfig.data.pop();
			config.chartConfig.data[0] = getChartDataFormatted({
				type,
				color,
				value: analyzerResult.variableAnnuity,
				label: toCurrency(analyzerResult.variableAnnuity)
			});
		} else if (configKey === contractCashValue) {
			config.chartConfig.sidebar.age = analyzerResult.age;
			config.chartConfig.data.pop();
			config.chartConfig.data[0] = getChartDataFormatted({
				legend: 'Protected from Market Losses',
				type,
				color,
				value: analyzerResult.variableAnnuity,
				label: toCurrency(analyzerResult.variableAnnuity)
			});
		} else if (configKey === incomeBenefitBase) {
			const [firstAnalyzerResult, secondAnalyzerResult] = analyzerResult;
			config.chartConfig[0].sidebar.age = firstAnalyzerResult.age;
			config.chartConfig[0].data.pop();
			config.chartConfig[0].data[0] = getChartDataFormatted({
				type,
				color,
				value: firstAnalyzerResult.variableAnnuity,
				label: toCurrency(firstAnalyzerResult.variableAnnuity)
			});
			config.chartConfig[1].sidebar.age = secondAnalyzerResult.age;
			config.chartConfig[1].data.pop();
			config.chartConfig[1].data[0] = getChartDataFormatted({
				type,
				color,
				value: secondAnalyzerResult.variableAnnuity,
				label: toCurrency(secondAnalyzerResult.variableAnnuity)
			});
		} else if (configKey === payoutPercentage) {
			config.chartConfig.sidebar.age = analyzerResult.age;
			config.chartConfig.data.pop();
			config.chartConfig.data[0] = getChartDataFormatted({
				type,
				color,
				value: analyzerResult.variableAnnuity,
				label: toPercentage(analyzerResult.variableAnnuity)
			});
		}
		config.highestGlobalValue = getHighestGlobalValue(analyzerResults);
		return config;
	});
	return configs;
};
