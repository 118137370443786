import React from 'react';
import { Link } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

const useStyles = makeStyles(theme => ({
	link: {
		display: 'flex',
		fontFamily: 'Gotham-Bold'
	},
	icon: {
		marginRight: theme.spacing(0.5),
		width: 20,
		height: 20
	},
	separator: {
		fontFamily: 'Gotham-Bold',
		color: '#90abb7',
		marginTop: 2
	}
}));

export const CustomBreadcrumbs = ({
	breadcrumbs,
	activeColor = '#0047bb',
	color = '#90abb7'
}) => {
	const classes = useStyles();

	return (
		<Breadcrumbs aria-label='breadcrumb' className={classes.separator}>
			{breadcrumbs.map(({ breadcrumb, path, match, active }) => (
				<Link
					key={path}
					className={`${classes.link} breadcrumbs-link`}
					color='inherit'
					to={match.url}
					style={{
						color: active ? activeColor : color,
						cursor: active ? 'default' : 'pointer',
						textDecoration: 'none'
					}}
				>
					{match.url === '/' ? (
						<HomeIcon className={classes.icon} />
					) : (
						breadcrumb
					)}
				</Link>
			))}
		</Breadcrumbs>
	);
};
