import React, { useEffect } from 'react';

import { noop } from 'lodash';

import './styles.scss';

import DarkOverlay from '../DarkOverlay';

const UserAgreement = ({ onAgree, onCancel, visible = false }) => {
	useEffect(() => {
		document.body.style.overflow = visible === true ? 'hidden' : 'auto';
	}, [visible]);

	return (
		<DarkOverlay visible={visible}>
			<div className='user-agreement-container'>
				<div className='user-agreement-group'>
					<label className='form-section-title'>
						For Registered Rep and RIA use only
					</label>
					<div className='user-agreement-group-content'>
						<p>
							The New Heights Select Guaranteed Income Comparison Tool is intended for
							Registered Reps and RIA use only and is not for use with the
							general public.
						</p>
						<p>
							By selecting "Agree," I certify that I am a Registered Rep or RIA
							authorized to use this tool and that I will not use this tool with
							clients or prospective purchasers.
						</p>
					</div>
					<div className='user-agreement-group-options'>
						<button className='agree-button' onClick={onAgree}>
							Agree
						</button>
						<button className='cancel-button' onClick={onCancel}>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</DarkOverlay>
	);
};

UserAgreement.defaultProps = {
	onAgree: noop,
	onCancel: noop
};

export default UserAgreement;
