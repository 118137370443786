import React, { useEffect, useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import { TextField } from 'formik-material-ui';
import { Field, getIn } from 'formik';
import Downshift from 'downshift';
import { MenuItems } from './dropDownMenuItems';
import { filterItem } from './helper';
import './styles.scss';

export function AutoCompleteSelect({
	name,
	label,
	className,
	itemToKey,
	onSelection,
	itemToString,
	initialValue,
	textFieldProps,
	downshiftProps,
	suggestionItems,
	showMessageErrors = false
}) {

	const [suggestions, setSuggestions] = useState([]);
	const [value, setValue] = useState(initialValue || '');

	useEffect(() => {
		setValue(initialValue || '');
		getSuggestions();
	}, []);

	const getSuggestions = async () => {
		if (Array.isArray(suggestionItems)) {
			setSuggestions(suggestionItems);
			return;
		}
		const suggestionsResult = await suggestions();
		setSuggestions(suggestionsResult);
	};


	const onFocus = async () => {
		await getSuggestions();
	};

	const buildFieldProps = (field) => {
		return {
			...field,
			value: itemToString(field.value),
		};
	};

	const handleStateChange = changes => {
		if (changes.hasOwnProperty('selectedItem')) {
			setValue(changes.selectedItem.text);
			onSelection && onSelection(changes.selectedItem.text);
		} else if (changes.hasOwnProperty('inputValue') || changes.inputValue === '') {
			setValue(changes.inputValue);
			onSelection && onSelection(changes.inputValue);
		}
	};

	const handleArrowClick = async (form, isOpen, closeMenu, openMenu) => {
		if (form.isSubmitting) {
			return;
		}
		if (suggestions.length === 0) {
			await getSuggestions();
		}
		return (isOpen ? closeMenu() : openMenu());
	};

	const handleOnKeyPress = (ev, highlightedIndex, inputValue) => {
		if (ev.key === 'Enter') {
			ev.preventDefault();
			if (!!!highlightedIndex) {
				const [highlightedItem] = suggestions.filter((suggestion, index, allSuggestions) =>
					filterItem(inputValue, suggestion, allSuggestions, itemToString),
				);
				handleStateChange({ inputValue: highlightedItem.value });
			}
		}
	};

	return (
		<div className='autocomplete-combo-box-wrapper'>
			<Field name={name}>
				{({ field, form }) => (
					<Downshift
						{...downshiftProps}
						selectedItem={value}
						itemToString={itemToString}
						onStateChange={state => handleStateChange(state)}
					>
						{({
							getInputProps,
							getItemProps,
							getLabelProps,
							getMenuProps,
							isOpen,
							highlightedIndex,
							openMenu,
							closeMenu,
							inputValue,
						}) => {
							const InputProps = {
								endAdornment: (
									<InputAdornment position='end'>
										<div className={`icons-container ${form.isSubmitting ? 'icons-container-disabled' : ''}`}>
											<div className='arrow'
												onClick={event => form.isSubmitting || handleArrowClick(form, isOpen, closeMenu, openMenu)}
												tabIndex={-1}>
												{isOpen ? <ArrowDropUp className='arrow-icon' /> : <ArrowDropDown className='arrow-icon' />}
											</div>
										</div>
									</InputAdornment>
								),
								...(textFieldProps && textFieldProps.InputProps),
								...getInputProps({ onFocus: onFocus, onBlur: field.onBlur, value: inputValue || '' }),
							};
							return (
								<div className={`autocomplete-container ${showMessageErrors ? '' : 'errors-hidden'} ${className || ''}`}>
									<TextField
										fullWidth
										form={form}
										label={label}
										variant='filled'
										{...textFieldProps}
										InputProps={InputProps}
										field={buildFieldProps(field)}
										InputLabelProps={{ shrink: !!value }}
										onKeyPress={(ev) => handleOnKeyPress(ev, highlightedIndex, inputValue)}
										error={!!(getIn(form.touched, field.name) && getIn(form.errors, field.name))}
									/>
									{isOpen && <MenuItems
										itemToKey={itemToKey}
										inputValue={inputValue}
										suggestions={suggestions}
										itemToString={itemToString}
										getItemProps={getItemProps}
										getMenuProps={getMenuProps}
										highlightedIndex={highlightedIndex} />}
								</div>
							);
						}}
					</Downshift>
				)}
			</Field>
		</div>
	);
}