import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import _ from 'lodash';

import { compose } from '../../utils';
import { withContext } from '../../contexts/appContext';
import { withNavigation } from '../../utils/WithNavigation';
import { withProtection } from '../../utils/WithProtection';
import { saveAnalyzerPayloadOnLocalStorage } from '../../api/AnalyzerService';

import { ResultsCard } from '../../components/shared/ResultsCard';
import { ResultsContainer } from '../../components/shared/ResultsContainer';

import { HEADERS, ANALYZER_RESULTS_SECTION_KEYS, FIA } from '../../constants';
import { getDoingGreatConfig, getMoreGuaranteedIncomeConfig } from './helper';

import { toCurrency } from '../../utils/UtilHelper';
import { withBreadcrumbs } from '../../utils/WithBreadcrumbs';
import ContinueToIllustratorModal from '../../components/ContinueToIllustratorModal';

const routes = [
	{ path: '/', breadcrumb: 'home' },
	{ path: '/analyzer/info', breadcrumb: 'Info' },
	{ path: '/analyzer/info/results', breadcrumb: 'Results' }
];

export function AnalyzerResults({
	navigateTo,
	breadcrumbs,
	analyzerPayload,
	setFooterName,
	setAnalyzerPayload,
	userInfo,
	auth,
	onCancel,
}) {
	const [modalVisible, setModalVisible] = useState(false);

	const analyzerOutput = _.get(analyzerPayload, 'output', null);
	const analyzerInput = _.get(analyzerPayload, 'input', null);
	const currentTypeIsFIA = analyzerInput?.CurrentType === FIA;

	if (!analyzerOutput || !analyzerInput) {
		return <Redirect to='/analyzer/info' />;
	}

	const goBackToInfo = () => {
		navigateTo('/analyzer/info', {}, true);
	};
	const cleanInfo = () => {
		const emptyPayload = { input: null, output: null };
		setAnalyzerPayload(emptyPayload);
		saveAnalyzerPayloadOnLocalStorage(emptyPayload);
		navigateTo('/analyzer/info', {}, true);
	};

	const { moreGuaranteedIncome, doingGreat } = ANALYZER_RESULTS_SECTION_KEYS;
	
	useEffect(() => {
		if (analyzerOutput.resultSection === doingGreat) {
			setFooterName('common-footer');
		} else {
			setFooterName('result-footer');
		}
	},[]);

	let resultsCardConfigs = null;

	if (analyzerOutput.resultSection === moreGuaranteedIncome) {
		resultsCardConfigs = getMoreGuaranteedIncomeConfig(analyzerOutput, currentTypeIsFIA);
	} else if (analyzerOutput.resultSection === doingGreat) {
		resultsCardConfigs = getDoingGreatConfig(analyzerOutput);
	} else {
		resultsCardConfigs = getMoreGuaranteedIncomeConfig(analyzerOutput, currentTypeIsFIA);
	}

	const getHeaderProps = headerType => {
		if (headerType === HEADERS.MoreGuaranteedIncome) {
			return {
				guaranteedIncome: Math.round(analyzerOutput.moreGuaranteedIncome),
				goBackToInfo,
				setModalVisible,
				isJoint: analyzerInput.OwnerType === 'Joint',
				isFIA: currentTypeIsFIA
			};
		} else if (headerType === HEADERS.SimilarAmounts) {
			return {
				lifeTimeIncomeStartAge: analyzerInput.LifeTimeIncomeStartAge,
				contractCashValue: toCurrency(
					Math.round(analyzerInput.ContractCashValue)
				),
				goBackToInfo,
				setModalVisible,
				guaranteedIncome: analyzerOutput.guaranteedIncome,
				isJoint: analyzerInput.OwnerType === 'Joint',
				isFIA: currentTypeIsFIA
			};
		} else if (headerType === HEADERS.DoingGreat) {
			return {
				goBackToInfo,
				cleanInfo
			};
		}
		return null;
	};

	const renderContinueToIllustratorModal = () => {
		return (
			<ContinueToIllustratorModal
				visible={modalVisible}
				analyzerRunId={analyzerOutput.analyzerRunId}
				onCancel={() => setModalVisible(false)}
			/>
		);
	};

	return (
		<React.Fragment>
			{renderContinueToIllustratorModal()}
			<ResultsContainer
				breadcrumbs={breadcrumbs}
				headerType={HEADERS[analyzerOutput.resultSection]}
				headerProps={getHeaderProps(HEADERS[analyzerOutput.resultSection])}
				isAnnexusUser={userInfo.isAnnexusUser}
				analyzerRunId={analyzerOutput?.analyzerRunId}
				token={auth?.getToken()}
				onCancel={onCancel}
				isFIA={currentTypeIsFIA}
			>
				{analyzerOutput.resultSection !== doingGreat &&
					resultsCardConfigs &&
					resultsCardConfigs.map((resultsCardConfig, index) => {
						return (
							<ResultsCard
								key={`result-card-${index}`}
								{...resultsCardConfig}
								isFIA={currentTypeIsFIA}
							/>
						);
					})}
			</ResultsContainer>
		</React.Fragment>
	);
}

export default compose(
	withProtection(),
	withNavigation(),
	withContext(),
	withBreadcrumbs(routes)
)(AnalyzerResults);
