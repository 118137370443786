import React from 'react';
import { Button, Link } from '@material-ui/core';
import { CustomBreadcrumbs } from '../Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';
import { API_URL } from '../../../config';

import HeaderSimilarFIAVA from '../../HeaderSimilarFIAVA';
import HeaderFIA from '../../HeaderFIA';
import HeaderVA from '../../HeaderVA';

import './styles.scss';
const useStyles = makeStyles(theme => ({
	Link: {
		color: '#0047bb',
		fontFamily: 'Gotham-Book',
		fontSize: '16px'
	}
}));

export function ResultsContainer({
	headerType,
	headerProps,
	children,
	breadcrumbs,
	isAnnexusUser,
	isFIA,
	analyzerRunId,
	token,
	onCancel
}) {
	const loadHeader = headerType => {
		let HeaderComponent = null;
		switch (headerType) {
		case 'HeaderSimilarFIAVA':
			HeaderComponent = HeaderSimilarFIAVA;
			break;
		case 'HeaderFIA':
			HeaderComponent = HeaderFIA;
			break;
		case 'HeaderVA':
			HeaderComponent = HeaderVA;
			break;
		default:
			HeaderComponent = null;
		}
		return <HeaderComponent {...headerProps} />;
	};

	const loadButtons = () => {
		const { setModalVisible, goBackToInfo, cleanInfo } = headerProps || {};

		const handleContinueClick = () => {
			if (isFIA) {
				const clientObjective = '';
				const redirectUrl = `${API_URL}/api/v1/AnalyzerResults/RedirectToIllustrator?analyzerRunId=${analyzerRunId}&clientObjective=${clientObjective}&token=${token}`;
				window.open(redirectUrl, '_blank');
				onCancel && onCancel();
				document.activeElement.blur();
			} else {
				setModalVisible(true);
			}
		};

		return (
			<div
				className={`results-buttons-container ${
					headerType === 'HeaderVA' ? 'va' : ''
				}`}
			>
				{headerType === 'HeaderVA' && (
					<div>
						<Button type='button' className='run-new' onClick={cleanInfo}>
							Run Comparison Tool For Another Client
						</Button>
						<Button type='button' className='edit-button-return' onClick={goBackToInfo}>
							Edit Information
						</Button>
					</div>
				)}
				{headerType !== 'HeaderVA' && (
					<Button type='button' className='edit-button' onClick={goBackToInfo}>
						Edit Information
					</Button>
				)}
				{headerType !== 'HeaderVA' && isAnnexusUser && (
					<Button
						type='button'
						className='edit-button'
						onClick={handleContinueClick}
					>
						Continue to Illustrator
					</Button>
				)}
			</div>
		);
	};

	const goToEditInfo = event => {
		event.preventDefault();
		const { goBackToInfo } = headerProps || {};
		goBackToInfo();
	};

	const classes = useStyles();

	return (
		<>
			<div
				className={`panel-container header-container header-container-${headerType}`}
			>
				<div className='container header-content'>
					<div className='header-breadcrumbs-wrapper'>
						<div className='row justify-content-between'>
							<div className='col-6'>
								<CustomBreadcrumbs breadcrumbs={breadcrumbs} />
							</div>
							{headerType !== 'HeaderVA' && (
								<div className='col-6 text-right'>
									<Link
										style={{ textDecoration: 'none' }}
										className={classes.Link}
										href='#'
										onClick={goToEditInfo}
									>
										Edit Info
									</Link>
								</div>
							)}
						</div>
					</div>
					{loadHeader(headerType)}
				</div>
			</div>
			<div className='container body-container'>
				{children}
				{loadButtons()}
			</div>
		</>
	);
}