import React from 'react';
import { currentYear } from '../../../utils/UtilHelper';
import './styles.scss';

export function HomeFooter() {
	return (
		<div>
			<div>
				<p className='bold-text'>
					FOR FINANCIAL PROFESSIONAL AND RIA USE ONLY - NOT FOR USE WITH THE PUBLIC
				</p>
			</div>
			<p>
				This tool is designed to help you find out whether Nationwide New
				Heights<sup>&reg;</sup> Select featuring Nationwide High Point 365
				<sup>&reg;</sup> Select with Bonus may offer greater income or protection from
				market losses than your client's current solution for guaranteed
				retirement income using an annual roll-up. High Point 365 Select with Bonus is
				an optional rider available for an annual fee and may only be added at
				contract issue. The analysis presented is based on the guaranteed
				contract minimums and does not present the impact of withdrawals before
				beginning lifetime income. Your client's experience may differ. Clients
				may have other options that are better suited.
			</p>
			<p>
				This material is not a recommendation to buy or sell a financial product
				or to adopt an investment strategy. Investors should discuss their specific
				situation with their financial professional.
			</p>
			<div>
				<p>
					Nationwide New Heights Select fixed indexed annuities are issued by
					Nationwide Life and Annuity Insurance Company, Columbus, Ohio. All
					guarantees are subject to the claims paying ability of Nationwide Life
					and Annuity Insurance Company.
				</p>
			</div>

			<p>
				New Heights Select does not directly participate in the stock market or any
				index. It is not possible to invest in an index. Withdrawals are subject
				to income tax, and withdrawals before age 59½ may be subject to a 10%
				federal tax penalty.
			</p>
			<div>
				<p>
					Annuities have limitations. They are long-term vehicles designed for
					retirement purposes. They are not intended to replace emergency funds,
					to be used as income for day-to-day expenses or to fund short-term
					savings goals. Please read the contract for complete details.
				</p>
			</div>
			<p>
				Guarantees and protections are subject to the claims-paying ability of
				Nationwide Life and Annuity Insurance Company.
			</p>
			<div>
				<p>
					Nationwide, the Nationwide N and Eagle, Nationwide is on your side,
					Nationwide New Heights Select, New Heights Select, Nationwide High Point 365 Select and
					High Point 365 Select are service marks of the Nationwide Mutual Insurance
					Company. © {currentYear()} Nationwide
				</p>
			</div>
			<div className='form-number'>
				<p>FAM-1211AO.2 (10/23)</p>
			</div>
		</div>
	);
}
