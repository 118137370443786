import { asBoolean } from '../utils';
import { doRequest } from '../utils/ApiHelper';

const AGREEMENT_KEY = 'VA_EXCHANGE_ANALYZER_AGREEMENT_VALUE';

export const hasUserAgreed = () => {
	const agreed = asBoolean(localStorage.getItem(AGREEMENT_KEY)) === true;
	return Promise.resolve(agreed);
};

/**
 *
 * @param {boolean} val - User's value to be set on localStorage
 */
export const setUserAgreementVal = async () => {
	try {
		const response = await doRequest({
			url: 'api/v1/Session/AcceptAgreement',
			method: 'POST'
		});
		return response;
	} catch (err) {
		console.error('Error trying to accept agreement', err);
		return null;
	}
};
