import React, { useState, useEffect } from 'react';

import { fetchCatalogs } from '../api/CatalogsService';

export const withCatalogs = (catalogNames = []) => ScreenComponent => {
	return props => {
		const [catalogs, setCatalogs] = useState({});
        
		useEffect(() => {
			if (props.userAgrees) {
				fetchCatalogs(catalogNames).then(catalogs => {
					setCatalogs(catalogs);
				});
			}
		}, [props.userAgrees]);

		return <ScreenComponent {...props} {...catalogs} />;
	};
};
