import React from 'react';

export function CheckMarkIcon(props) {
	return (
		<svg {...props} width='25' height='25' viewBox='0 0 25 25'>
			<g>
				<g opacity='.9'>
					<path fill='#1db707' d='M21.914 12.188h2.461c0 3.359-1.191 6.23-3.574 8.613-2.383 2.383-5.254 3.574-8.613 3.574-3.36 0-6.231-1.191-8.614-3.574C1.191 18.418 0 15.547 0 12.188c0-3.36 1.191-6.231 3.574-8.614C5.957 1.191 8.828 0 12.188 0c1.562 0 3.085.313 4.57.938l-1.875 1.875a10.199 10.199 0 0 0-2.696-.352c-2.695 0-4.99.947-6.884 2.842-1.895 1.894-2.842 4.19-2.842 6.885 0 2.695.947 4.99 2.842 6.884 1.894 1.895 4.19 2.842 6.885 2.842 2.695 0 4.99-.947 6.884-2.842 1.895-1.894 2.842-4.19 2.842-6.884zM7.206 9.844l3.748 3.75 10.482-10.43 1.699 1.7L10.954 17.05 5.508 11.6z' />
				</g>
			</g>
		</svg>
	);
}