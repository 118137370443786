import React from 'react';

export function BlackShieldIcon({ className = '' }) {
	return (
		<div className={className}>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='78'
				height='88'
				viewBox='0 0 78 88'
			>
				<g>
					<g>
						<path
							fill='#253746'
							d='M39 0L0 12.998V35.13C0 59.72 15.908 81.325 39 88c23.092-6.675 39-28.28 39-52.87V12.998z'
						/>
					</g>
					<g>
						<path
							fill='#fff'
							d='M35.8 58.8L21 46.6l3.2-3.9 11 9L54 30l3.8 3.3z'
						/>
					</g>
				</g>
			</svg>
		</div>
	);
}
