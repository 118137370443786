import { doRequest } from '../utils/ApiHelper';

export async function getSessionInfo() {
	try {
		const response = await doRequest({
			url: 'api/v1/Session/Me',
			method: 'GET'
		});
		return response;
	} catch (err) {
		console.error('Error trying to get the user info', err);
		return null;
	}
}
