import React from 'react';

export function ChartBackground({ height }) {
	return (
		<g>
			<defs>
				<linearGradient id='sniqa' x1='0' x2='914' y1='0' y2='210' gradientUnits='userSpaceOnUse'>
					<stop offset='0' stopColor='#fff' stopOpacity='0' />
					<stop offset='1' stopOpacity='.03' />
				</linearGradient>
			</defs>
			<g>
				<g>
					<path fill='#f9fafb' d={`M0 0h1000a4  4 0 0 1 4 4v${height}a4  4 0 0 1-4 4H0z`} />
					<path fill='url(#sniqa)' d={`M0 0h1000a4  4 0 0 1 4 4v${height}a4  4 0 0 1-4 4H0z`} />
				</g>
			</g>
			<g>
				<g opacity='.5'>
					<path
						fill='none'
						stroke='#979797'
						strokeDasharray='8 4'
						strokeLinecap='square'
						strokeMiterlimit='50'
						strokeWidth='.5'
						d='M290.589 113V3'
					/>
				</g>
				<g opacity='.5'>
					<path
						fill='none'
						stroke='#979797'
						strokeDasharray='8 4'
						strokeLinecap='square'
						strokeMiterlimit='50'
						strokeWidth='.5'
						d='M415.819 113V3'
					/>
				</g>
				<g opacity='.5'>
					<path
						fill='none'
						stroke='#979797'
						strokeDasharray='8 4'
						strokeLinecap='square'
						strokeMiterlimit='50'
						strokeWidth='.5'
						d='M532.179 113V3'
					/>
				</g>
				<g opacity='.5'>
					<path
						fill='none'
						stroke='#979797'
						strokeDasharray='8 4'
						strokeLinecap='square'
						strokeMiterlimit='50'
						strokeWidth='.5'
						d='M648.539 113V3'
					/>
				</g>
				<g opacity='.5'>
					<path
						fill='none'
						stroke='#979797'
						strokeDasharray='8 4'
						strokeLinecap='square'
						strokeMiterlimit='50'
						strokeWidth='.5'
						d='M763.769 113V3'
					/>
				</g>
				<g opacity='.5'>
					<path
						fill='none'
						stroke='#979797'
						strokeDasharray='8 4'
						strokeLinecap='square'
						strokeMiterlimit='50'
						strokeWidth='.5'
						d='M878.999 113V3'
					/>
				</g>
			</g>
		</g>
	);
};