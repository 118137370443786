import React from 'react';

import Layout from '../../components/shared/Layout';

export default function DefaultLayout ({
	children
}) {
	return (
		<Layout>
			{children}
		</Layout>
	);
}
