import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const CustomNumberFormat = ({
	inputRef,
	onChange,
	decimalscale,
	returntypevalue,
	prefix,
	suffix,
	...rest
}) => {
	const handleOnChange = values => {
		let value = null;
		switch (returntypevalue) {
		case 'formattedValue':
			value = values.formattedValue === undefined ? '' : values.formattedValue;
			break;
		case 'floatValue':
			value = values.floatValue === undefined ? '' : values.floatValue;
			break;
		default:
			value = values.value === undefined ? '' : values.value;
			break;
		}
		return onChange({
			currentTarget: {
				value: value
			},
			target: {
				value: value
			}
		});
	};
	return (
		<NumberFormat
			{...rest}
			getInputRef={inputRef}
			onValueChange={values => {
				handleOnChange(values);
			}}
			thousandSeparator
			inputMode='decimal'
			prefix={prefix}
			suffix={suffix}
			allowNegative={false}
			decimalScale={decimalscale}
		/>
	);
};

CustomNumberFormat.propTypes = {
	inputRef: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	decimalscale: PropTypes.number,
	returntypevalue: PropTypes.string.isRequired
};

CustomNumberFormat.defaultProps = {
	decimalscale: 0,
	fixedDecimalScale: false,
	returntypevalue: 'floatValue',
	prefix:'',
	suffix:'',
};

export default CustomNumberFormat;
