import React from 'react';

import { Redirect } from 'react-router-dom';

export const withProtection = () => ScreenComponent => {
	return props => {
		if (!props.userAgrees) {
			return <Redirect to='/' />;
		}
		return <ScreenComponent {...props} />;
	};
};