import React, { useLayoutEffect, createRef } from 'react';
import { Field, ErrorMessage, getIn } from 'formik';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import RootRef from '@material-ui/core/RootRef';
import { PropTypes } from 'prop-types';

const SelectComponent = ({
	field,
	form: { touched, errors, setFieldValue },
	options,
	label,
	extraIcon,
	handleOnChange,
	disabled,
	isErrorMessageDisplayed,
	...props
}) => {
	const domRef = createRef();
	const ExtraIcon = extraIcon;
	const hasErrors = !!(getIn(touched, field.name) && getIn(errors, field.name));
	useLayoutEffect(() => {
		const elem = domRef.current;
		const ctrl = elem.getElementsByTagName('select')[0];
		if (field.value === undefined) {
			ctrl.selectedIndex = -1;
		}
	});

	const handleSelectOnChange = event => {
		if (handleOnChange) {
			handleOnChange(event);
		}
		setFieldValue(field.name, event.target.value);
	};

	return (
		<div>
			<FormControl variant='filled' fullWidth disabled={disabled}>
				<InputLabel
					htmlFor='filled-age-simple'
					error={hasErrors}
					shrink={!!field.value}
				>
					{label}
				</InputLabel>
				<RootRef rootRef={domRef}>
					<Select
						inputProps={{
							defaultChecked: false
						}}
						autoWidth
						native
						IconComponent={() => (
							<div className='icons-container'>
								<div className='arrow'>
									<ArrowDropDown className='arrow-icon' />
								</div>
							</div>
						)}
						input={<FilledInput name={field.name} disableUnderline fullWidth />}
						{...props}
						{...field}
						error={hasErrors}
						onChange={handleSelectOnChange}
					>
						{options &&
							options.map((option, index) => (
								<option key={index} value={option.value}>
									{option.text}
								</option>
							))}
					</Select>
				</RootRef>
				{!!extraIcon && (
					<div className='extra-icon'>
						<ExtraIcon />
					</div>
				)}
			</FormControl>
			{isErrorMessageDisplayed && (
				<ErrorMessage component='span' name={field.name} />
			)}
		</div>
	);
};

SelectComponent.defaultProps = {
	isErrorMessageDisplayed: false
};

SelectComponent.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	options: PropTypes.array,
	label: PropTypes.string,
	extraIcon: PropTypes.bool,
	disabled: PropTypes.bool,
	handleOnChange: PropTypes.func,
	isErrorMessageDisplayed: PropTypes.bool
};

const SelectField = props => <Field component={SelectComponent} {...props} />;

export default SelectField;
