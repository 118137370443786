export const GO_BACK = 'GO_BACK';
export const HEADERS = {
	SimilarAmounts: 'HeaderSimilarFIAVA',
	MoreGuaranteedIncome: 'HeaderFIA',
	DoingGreat: 'HeaderVA'
};
export const ANALYZER_RESULT_KEYS = {
	guaranteedIncome: 'guaranteedIncome',
	contractCashValue: 'contractCashValue',
	incomeBenefitBase: 'incomeBenefitBase',
	payoutPercentage: 'payoutPercentage'
};

export const ANALYZER_RESULTS_SECTION_KEYS = {
	moreGuaranteedIncome: 'MoreGuaranteedIncome',
	doingGreat: 'DoingGreat',
	similarAmounts: 'SimilarAmounts'
};

export const OWNER_TYPES = {
	SINGLE: 'Single',
	JOINT: 'Joint'
};

export const ORIENTATION = {
	VERTICAL: 'vertical',
	HORIZONTAL: 'horizontal'
};

export const CALCULATION_METHODS = {
	SIMPLE: 'Simple',
	COMPOUND: 'Compound',
	COMPOUNDED: 'Compounded'
};

export const BROWSER_TYPE = {
	IE: 'ie',
	EDGE: 'edge'
};

export const MAX_BAR_HEIGHT_MOBILE = 290;

export const FIA_CONTAINER_SIZE = 75;

export const FIA = 'FIA';