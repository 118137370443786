import React from 'react';

export function BlueShieldIcon({ className = '' }) {
	return (
		<div className={className}>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='91'
				height='89'
				viewBox='0 0 91 89'
			>
				<defs>
					<clipPath id='ns1na'>
						<path d='M0 0h50v89H0z' />
					</clipPath>
					<clipPath id='ns1nb'>
						<path d='M50 0h41v89H50z' />
					</clipPath>
				</defs>
				<g>
					<g>
						<g>
							<g>
								<g clipPath='url(#ns1na)'>
									<path
										fill='#0047bb'
										d='M94.4 45l-7.6-9.9 1.7-12.3-11.6-4.6-4.6-11.6L60 8.3 50 .6l-9.9 7.6-12.3-1.7-4.6 11.6-11.6 4.6L13.3 35 5.6 45l7.6 9.9-1.7 12.3 11.6 4.6 4.6 11.6L40 81.7l9.9 7.6 9.9-7.6 12.3 1.7 4.6-11.6 11.6-4.7-1.7-12.3z'
									/>
								</g>
							</g>
						</g>
						<g>
							<g clipPath='url(#ns1nb)'>
								<path
									fill='#253746'
									d='M50 1L11 13.998V36.13C11 60.72 26.908 82.325 50 89c23.092-6.675 39-28.28 39-52.87V13.998z'
								/>
							</g>
						</g>
						<g>
							<path
								fill='#fff'
								d='M46.8 59.8L32 47.6l3.2-3.9 11 9L65 31l3.8 3.3z'
							/>
						</g>
					</g>
				</g>
			</svg>
		</div>
	);
}
