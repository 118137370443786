export const setupValues = {
	guaranteedIncome: {
		title: {
			title: 'Annual Guaranteed Income',
			superscript: '1'
		},
		sectionKey: 'guaranteedIncome',
		maxSizePercentage: 47,
		chartConfig: {
			bar: {
				width: 30,
				margin: 5
			},
			sidebar: {
				age: 0
			},
			data: []
		},
		description:
			'The youngest covered life must be age 50 and have held the contract for one year before beginning lifetime income withdrawals. Your client may be giving up immediate access to lifetime income payments by transferring funds from a variable annuity.',
		descriptionFIA:
			'The youngest covered life must be age 50 and have held the contract for one year before beginning lifetime income withdrawals.'
	},
	contractCashValue: {
		title: {
			title: 'Contract Value',
			superscript: '2'
		},
		sectionKey: 'contractCashValue',
		maxSizePercentage: 95,
		chartConfig: {
			bar: {
				width: 30,
				margin: 5
			},
			sidebar: {
				age: 0
			},
			data: []
		},
		description:
			'High Point 365 Select with Bonus has an annual rider charge of 1.10% for the life of the contract, calculated on the High Point Income Benefit Base and deducted from the Contract Value.'
	},
	incomeBenefitBase: {
		title: {
			title: 'Income Benefit Base',
			superscript: '3'
		},
		sectionKey: 'incomeBenefitBase',
		maxSizePercentage: 95,
		chartConfig: [
			{
				bar: {
					width: 30,
					margin: 5
				},
				sidebar: {
					age: 0
				},
				data: []
			},
			{
				bar: {
					width: 30,
					margin: 5
				},
				sidebar: {
					age: 0
				},
				data: []
			}
		],
		description: ''
	},
	payoutPercentage: {
		title: {
			title: 'Payout Percentage'
		},
		sectionKey: 'payoutPercentage',
		maxSizePercentage: 95,
		chartConfig: {
			bar: {
				width: 30,
				margin: 5
			},
			sidebar: {
				age: 0
			},
			data: []
		},
		description:
			'The youngest covered life must be age 50 and have held the contract for one year before beginning lifetime income withdrawals. Your client may be giving up immediate access to lifetime income payments by transferring funds from a variable annuity.',
		descriptionFIA:
			'The youngest covered life must be age 50 and have held the contract for one year before beginning lifetime income withdrawals.'
	}
};
