import React, { useEffect, useState } from 'react';

import { Button, Radio } from '@material-ui/core';

import DarkOverlay from '../shared/DarkOverlay';

import { BlackShieldIcon } from '../shared/BlackShieldIcon';
import { BlueShieldIcon } from '../shared/BlueShieldIcon';
import { BlueIncomeIcon } from '../shared/BlueIncomeIcon';
import { BlueArrowIcon } from '../shared/BlueArrowIcon';

import { API_URL } from '../../config';

import './styles.scss';

import { withContext } from '../../contexts/appContext';

export const RadioPanelButton = ({
	id,
	reasonSelected,
	setReasonSelected,
	children
}) => {
	const selected = reasonSelected === id;
	let styles = {};
	if (selected) {
		styles = {
			backgroundColor: 'rgba(28, 87, 165, 0.08)'
		};
	}
	return (
		<div className='panel' style={styles} onClick={() => setReasonSelected(id)}>
			<Radio
				color='default'
				className='choose-reason'
				checked={selected}
			/>
			{children}
		</div>
	);
};

export const ContinueToIllustratorModal = ({
	visible = false,
	onCancel,
	analyzerRunId,
	auth
}) => {
	const [reasonSelected, setReasonSelected] = useState('');

	useEffect(() => {
		document.body.style.overflow = visible === true ? 'hidden' : 'auto';
	}, [visible]);

	const handleContinueClick = () => {
		const redirectUrl = `${API_URL}/api/v1/AnalyzerResults/RedirectToIllustrator?analyzerRunId=${analyzerRunId}&clientObjective=${reasonSelected}&token=${auth.getToken()}`;
		window.open(redirectUrl, '_blank');
		onCancel && onCancel();
	};

	return (
		<DarkOverlay visible={visible}>
			<div className='continue-modal-container'>
				<div className='modal-content'>
					<label className='modal-title form-section-title'>
						What is your client’s primary objective for this 1035 exchange?
					</label>
					<div className='modal-content-cards-container'>
						<RadioPanelButton
							id='higherIncome'
							reasonSelected={reasonSelected}
							setReasonSelected={setReasonSelected}
						>
							<BlueIncomeIcon className='reason-icon' />
							<p className='light-text'>Looking for higher guaranteed income</p>
						</RadioPanelButton>
						<RadioPanelButton
							id='protectionVolatility'
							reasonSelected={reasonSelected}
							setReasonSelected={setReasonSelected}
						>
							<BlackShieldIcon className='reason-icon' />
							<p className='light-text'>
								Protecting my principal from 
								market volatility
							</p>
						</RadioPanelButton>
						<RadioPanelButton
							id='mixedProtection'
							reasonSelected={reasonSelected}
							setReasonSelected={setReasonSelected}
						>
							<BlueShieldIcon className='reason-icon' />
							<p className='light-text'>
								Looking for higher 
								 guaranteed income 
								 <b> AND </b>protecting my 
								 principal from 
								 market volatility
							</p>
						</RadioPanelButton>
						<RadioPanelButton
							id='maximizeAccount'
							reasonSelected={reasonSelected}
							setReasonSelected={setReasonSelected}
						>
							<BlueArrowIcon className='reason-icon' />
							<p className='light-text last-text'>
								Maximize account
								 growth  
								<span > (regardless of market 
									 volatility or risk)
								</span>
							</p>
						</RadioPanelButton>
					</div>
					<div className='modal-content-buttons'>
						<Button
							type='button'
							className='continue-button'
							onClick={handleContinueClick}
							disabled={!reasonSelected}
						>
							Continue
						</Button>
						<Button
							className='cancel-button'
							type='button'
							onClick={onCancel}
						>
							Cancel
						</Button>
					</div>
				</div>
			</div>
		</DarkOverlay>
	);
};

const ModalWithContext = withContext()(ContinueToIllustratorModal);

export default ModalWithContext;
