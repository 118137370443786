import React from 'react';

import { NavMenu } from '../NavMenu';

import { SmartFooter } from '../SmartFooter';

import { withContext } from '../../../contexts/appContext';

import './styles.scss';

export function Layout({
	children,
	userInfo,
	footerName
}) {
	return (
		<div>
			<NavMenu userInfo={userInfo} />
			<div className='layout-content'>
				{children}
			</div>
			<SmartFooter footerName={footerName} />
		</div>
	);
}

export default withContext()(Layout);
