export const normalizeValue = maxBarHeight => (value, max, min = 0, maxSizePercentage = 100) => {
	return value === 0 ? 0 : parseInt((value - min) / (max - min) * maxBarHeight) * (maxSizePercentage / 100);
};

export const getStartingPositionInX = (dataLength, maxWidth, barWidth, index, barPadding) => {
	const isSingleItem = dataLength === 1;
	const svgInitialOffset = 22;
	const quadrant = (maxWidth / (isSingleItem ? dataLength * 2 : dataLength));
	const x = (((quadrant / dataLength) - (barWidth / 2) + (quadrant * index)) + svgInitialOffset);
	if (isSingleItem) {
		return x;
	}
	return index === 0 ? x + barPadding : x - barPadding;
};