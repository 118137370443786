import React from 'react';
import PropTypes from 'prop-types';
import { Field, getIn, ErrorMessage } from 'formik';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Hint from '../Hint';

const InputComponent = ({
	field,
	maxLength,
	form: { touched, errors, setFieldValue },
	hintText,
	label,
	numberFormatCustom,
	decimalscale,
	isErrorMessageDisplayed,
	returntypevalue,
	handleBeforeOnChange,
	handleAfterOnChange,
	disabled,
	prefix,
	suffix,
	...props
}) => {
	const NumberFormatCustom = numberFormatCustom;
	const hasErrors = !!(getIn(touched, field.name) && getIn(errors, field.name));
	const handleTextChange = event => {
		if (handleBeforeOnChange) {
			handleBeforeOnChange(event.currentTarget.value, field.value);
		}
		setFieldValue(field.name, event.currentTarget.value);
		if (handleAfterOnChange) {
			handleAfterOnChange(event.currentTarget.value, field.value);
		}
	};

	return (
		<div>
			<FormControl variant='filled' fullWidth disabled={disabled}>
				<InputLabel
					error={hasErrors}
					shrink={!!field.value || field.value === 0}
				>
					{label}
				</InputLabel>
				<FilledInput
					inputProps={{
						maxLength,
						decimalscale,
						returntypevalue, 
						prefix,
						suffix,
					}}
					disableUnderline
					{...props}
					{...field}
					inputComponent={!numberFormatCustom ? 'input' : NumberFormatCustom}
					error={hasErrors}
					autoComplete='off'
					onChange={handleTextChange}
				/>
				{!!hintText && (<Hint text={hintText}/>)}
			</FormControl>
			{isErrorMessageDisplayed && (
				<ErrorMessage component='span' className='error-message' name={field.name} />
			)}
		</div>
	);
};

InputComponent.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	maxLength: PropTypes.number,
	extraIcon: PropTypes.bool,
	label: PropTypes.any,
	numberFormatCustom: PropTypes.any,
	decimalscale: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isErrorMessageDisplayed: PropTypes.bool,
	returntypevalue: PropTypes.any,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	handleBeforeOnChange: PropTypes.func,
	handleAfterOnChange: PropTypes.func
};
InputComponent.defaultProps = {
	isErrorMessageDisplayed: false, 
};

const FormikInput = props => <Field component={InputComponent} {...props} />;

export default FormikInput;
