import React, { useState } from 'react';
import BrowserDetection from 'react-browser-detection';
import { FIA_CONTAINER_SIZE } from '../../../constants';

export function SidebarSection({
	showVALegend,
	showFIALegend,
	age,
	height,
	width,
	isFIA,
}) {
	const labelPos = isFIA ? 97 : 108;
	const defaultTranslates = {
		labels: {
			VA: `translate(${labelPos} 64)`,
			FIA: `translate(${labelPos} 29)`,
			at: 'translate(38 33)',
			Age: 'translate(21 54)',
			standAloneVAFIA: `translate(${labelPos} 45)`
		}
	};
	const startPoint = isFIA ? FIA_CONTAINER_SIZE : 0;
	const [browserTranslate, setBrowserTranslate] = useState({ ...defaultTranslates });

	const browserHandler = {
		ie: () => {
			setBrowserTranslate({
				labels: {
					VA: `translate(${labelPos} 80)`,
					FIA: `translate(${labelPos} 45)`,
					at: 'translate(38 48)',
					Age: 'translate(21 69)',
					standAloneVAFIA: `translate(${labelPos} 61)`
				}
			});
			return (null);
		},
		safari: () => {
			setBrowserTranslate({
				labels: {
					VA: `translate(${labelPos} 80)`,
					FIA: `translate(${labelPos} 45)`,
					at: 'translate(38 48)',
					Age: 'translate(21 69)',
					standAloneVAFIA: `translate(${labelPos} 61)`
				}
			});
			return (null);
		},
		default: () => {
			setBrowserTranslate({ ...defaultTranslates });
			return (null);
		},
	};

	const SidebarLegends = ({ showVALegend, showFIALegend, isFIA }) => {
		const { labels } = browserTranslate;
		const fiaLabel = isFIA ? 'Nationwide FIA' : 'FIA';
		const vaLabel = isFIA ? 'Current FIA' : 'VA';
		if (showVALegend && showFIALegend) {
			return (
				<g>
					<g>
						<text dominantBaseline='text-before-edge' style={{ fontKerning: 'normal' }}
							fill='#555' fontFamily='Gotham-Book' fontSize='16' fontWeight='bold' transform={labels.FIA}>
							<tspan style={{ fontKerning: 'normal' }}>{fiaLabel}</tspan>
						</text>
					</g>
					<g>
						<text dominantBaseline='text-before-edge' style={{ fontKerning: 'normal' }}
							fill='#555' fontFamily='Gotham-Book' fontSize='16' fontWeight='bold' transform={labels.VA}>
							<tspan style={{ fontKerning: 'normal' }}>{vaLabel}</tspan>
						</text>
					</g>
				</g>);
		}
		return (
			<g>
				<g>
					<text dominantBaseline='text-before-edge' style={{ fontKerning: 'normal' }}
						fill='#555' fontFamily='Gotham-Book' fontSize='16' fontWeight='bold' transform={labels.standAloneVAFIA}>
						<tspan style={{ fontKerning: 'normal' }}>{showVALegend ? vaLabel : fiaLabel}</tspan>
					</text>
				</g>
			</g>);
	};

	return (
		<>
			<BrowserDetection>
				{browserHandler}
			</BrowserDetection>
			<g>
				<defs>
					<linearGradient id='rnnca' x1='0' x2={width} y1='0' y2='55' gradientUnits='userSpaceOnUse'>
						<stop offset='0' stopColor='#fff' stopOpacity='0' />
						<stop offset='1' stopOpacity='.08' />
					</linearGradient>
				</defs>
				<g>
					<g>
						<path fill='#fff' d={`M${startPoint} 4a4 4 0 0 1 4-4h153v${height}H4a4 4 0 0 1-4-4z`} />
						<path fill='url(#rnnca)' d={`M${startPoint} 4a4 4 0 0 1 4-4h153v${height}H4a4 4 0 0 1-4-4z`} />
					</g>
					<g>
						<path fill='#1c57a5' fillOpacity='.5' d={`M0 2a2 2 0 0 1 2-2h2v${height}H2a2 2 0 0 1-2-2z`} />
					</g>
					<g>
						<path fill='#1c57a5' fillOpacity='.5' d={`M84 0h1v${height}h-1z`} />
					</g>
					<g>
						<path fill='#fff' d={`M85 0h1v${height}h-1z`} />
					</g>
					<g>
						<SidebarLegends height={height} {...{ showVALegend, showFIALegend }} isFIA={isFIA} />
					</g>
					<g>
						<g>
							<text dominantBaseline='text-before-edge' style={{ fontKerning: 'normal' }} fill='#555' fontFamily='Gotham-Book' fontSize='16' transform={browserTranslate.labels.at}>
								<tspan fontFamily="'Gotham-Book','Gotham-Light'">at</tspan>
							</text>
							<text dominantBaseline='text-before-edge' style={{ fontKerning: 'normal' }} fill='#555' fontFamily='Gotham-Book' fontWeight='bold' fontSize='16' transform={browserTranslate.labels.Age}>
								<tspan fontFamily="'Gotham-Book','Gotham-Light'">Age {age}</tspan>
							</text>
						</g>
					</g>
				</g>
			</g>
		</>
	);
};