import React, { useEffect } from 'react';

export const withFooter = (footerName = 'home-footer') => (ScreenComponent) => {
	return (props) => {
		const { setFooterName } = props;
		useEffect(() => {
			setFooterName(footerName);
		}, [setFooterName]);
		return <ScreenComponent {...props} />;
	}; 
};
