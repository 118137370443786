import React from 'react';

import { Animated } from 'react-animated-css';

import './styles.scss';

const DarkOverlay = ({
	children,
	visible
}) => {
	return (
		<Animated
			className={`dark-overlay dark-overlay${visible ? '-visible' : ''}`}
			animationIn='fadeIn'
			animationOut='fadeOut'
			animationInDuration={400}
			animationOutDuration={400}
			animateOnMount={false}
			isVisible={visible}>
			{children}
		</Animated>
	);
};

export default DarkOverlay;
