import React from 'react';

export function BlueIncomeIcon({ className = '' }) {
	return (
		<div className={className}>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='90'
				height='90'
				viewBox='0 0 90 90'
			>
				<g>
					<g>
						<path
							fill='#0047bb'
							d='M89.4 45l-7.6-9.9 1.7-12.3-11.6-4.6-4.6-11.6L55 8.3 45 .6l-9.9 7.6-12.3-1.7-4.6 11.6-11.6 4.6L8.3 35 .6 45l7.6 9.9-1.7 12.3 11.6 4.6 4.6 11.6L35 81.7l9.9 7.6 9.9-7.6 12.3 1.7 4.6-11.6 11.6-4.7-1.7-12.3z'
						/>
					</g>
					<g>
						<path
							fill='#fff'
							d='M41.4 59.4L26.6 47.2l3.2-3.9 11 9 18.8-21.7 3.8 3.3z'
						/>
					</g>
				</g>
			</svg>
		</div>
	);
}
