import React from 'react';
import { compose } from '../../utils';
import { toCurrency } from '../../utils/UtilHelper';
import { withImages } from '../../utils/WithImages';
import { HeaderCardsComparison } from '../shared/HeaderCardsComparison';

import './styles.scss';

export function HeaderSimilarFIAVA({ guaranteedIncome, isJoint, isFIA }) {
	const amount = Math.abs(
		guaranteedIncome.fixingIndexAnnuity - guaranteedIncome.variableAnnuity
	).toFixed();
	const annuityCardsData = {
		variableAnnuityCard: {
			vaChecked: false,
			vaText: (
				<div className='percentage-label'>
					<div
						className='percent'
						style={{ fontFamily: 'Gotham-Light', fontSize: '50px' }}
					>
						{toCurrency(amount)}
					</div>
					<div className='label' style={{ marginLeft: 8 }}>
						Less Monthly Guaranteed Income<sup>1</sup>
					</div>
				</div>
			)
		},
		fixedIndexedAnnuityCard: {
			fiaChecked: true,
			fiaText: (
				<div className='percentage-label'>
					<div
						className='percent'
						style={{ fontFamily: 'Gotham-Light', fontSize: '50px' }}
					>
						100%
					</div>
					<div className='label' style={{ marginLeft: 8 }}>
						Protection from Market Losses
					</div>
				</div>
			)
		}
	};

	return (
		<div className='header-similar-options-wrapper'>
			<h3 className='result-fia'>Your client{isJoint ? 's' : ''} might...</h3>
			<h1 className='result-fia'>Benefit from greater protection</h1>
			<label className='form-section-title title-results result-fia'>
				Based on the information you entered, New Heights Select and High Point 365 Select
				with Bonus would provide...
			</label>
			<HeaderCardsComparison {...annuityCardsData} isFIA={isFIA} />
		</div>
	);
}

export default compose(
	withImages([
		{ id: 'goodIcon', src: '/good-icon.png', noWrap: true },
		{ id: 'newsIcon', src: '/news-icon.png', noWrap: true }
	])
)(HeaderSimilarFIAVA);
