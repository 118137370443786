import { doRequest } from '../utils/ApiHelper';

import isFunction from 'lodash/isFunction';

const catalogs = {
	states: 'api/v1/states',
	carriers: 'api/v1/carriers',
	products: ({ carrier }) => `api/v1/products?carrier=${carrier}`,
	ownerTypes: 'api/v1/ownerTypes',
	calculationMethods: 'api/v1/calculationMethods'
};

async function callEndpoint(catalog) {
	try {
		const response = await doRequest({ url: catalog.url, method: 'GET' });

		return response;
	} catch (err) {
		console.error(`Error trying to fetch the ${catalog.id}`, err);
		return null;
	}
}

export function fetchCatalogs(catalogNames = []) {
	return catalogNames.reduce(async (acc, id) => {
		const result = await acc;

		const data = catalogs[id];

		if (!data) {
			return result;
		}

		if (Array.isArray(data)) {
			result[id] = data;
			return result;
		}

		// lazy loading
		if (isFunction(data)) {
			result[id] = (options) => callEndpoint({ id, url: data(options) });
			return result;
		}

		result[id] = await callEndpoint({ id, url: data });

		return result;
	}, Promise.resolve({}));
}
