import React from 'react';
import { PortraitGroupedBars } from './groupedChart';
import { PortraitStandAloneChart } from './standAloneChart';
import './styles.scss';

export function PortraitBarChart({
	config,
	getHighestValue,
	highestGroupValue, 
	maxSizePercentage,
	isFIA
}) {
	return (
		<>
			{config instanceof Array ?
				(<PortraitGroupedBars
					config={config}
					highestGroupValue={highestGroupValue}
					maxSizePercentage={maxSizePercentage}
					isFIA={isFIA} />) :
				(<PortraitStandAloneChart
					config={config}
					getHighestValue={getHighestValue}
					highestGroupValue={highestGroupValue}
					maxSizePercentage={maxSizePercentage}
					isFIA={isFIA} />)}
		</>
	);
}