import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { ClickAwayListener } from '@material-ui/core';

import {
	splitDecimal,
	limitToScale,
	getNumbersAndDecimalsOnly,
	applyThousandSeparator
} from '../../../utils';

const CurrencyFormat = ({
	inputRef,
	onChange,
	decimalscale,
	fixedDecimalScale,
	returntypevalue,
	thousandSeparator,
	prefix,
	decimalSeparator,
	...rest
}) => {
	const [showPrefix, setShowPrefix] = useState(false);

	// values : {formattedValue: "$34", value: "34", floatValue: 34}
	const handleOnChange = values => {
		let value = null;
		switch (returntypevalue) {
		case 'formattedValue':
			value =
					values.formattedValue === undefined ? '' : values.formattedValue;
			break;
		case 'floatValue':
			value = values.floatValue === undefined ? '' : values.floatValue;
			break;
		default:
			value = values.value === undefined ? '' : values.value;
		  break;
		}

		return onChange({
			currentTarget: {
				value
			},
			target: {
				value
			}
		});
	};
	const handleFormat = formattedValueParam => {
		let formattedValue = formattedValueParam;
		// if decimal scale is zero remove decimal and number after decimalSeparator
		if (decimalSeparator && decimalscale === 0) {
			[formattedValue] = formattedValue.split(decimalSeparator);
		}
		let { beforeDecimal, afterDecimal, addNegation } = splitDecimal(
			formattedValue,
			false
		);

		const hasDecimalSeparator =
			formattedValue.indexOf('.') !== -1 ||
			(decimalscale && fixedDecimalScale && !!afterDecimal);

		if (decimalscale !== undefined && afterDecimal !== '')
			afterDecimal = limitToScale(
				afterDecimal,
				decimalscale,
				fixedDecimalScale
			);
		if (thousandSeparator) {
			beforeDecimal = applyThousandSeparator(beforeDecimal, thousandSeparator);
		}
		if (prefix) beforeDecimal = `${prefix}${beforeDecimal}`;
		if (addNegation) beforeDecimal = `-${beforeDecimal}`;

		return `${
			beforeDecimal +
			((hasDecimalSeparator && decimalSeparator) || '') +
			afterDecimal
		}`;
	};

	const handleRemoveFormatting = val => getNumbersAndDecimalsOnly(val);

	return (
		<ClickAwayListener onClickAway={() => setShowPrefix(false)}>
			<NumberFormat
				{...rest}
				thousandSeparator
				getInputRef={inputRef}
				onValueChange={values => {
					// values : {formattedValue: "$34", value: "34", floatValue: 34}
					handleOnChange(values);
				}}
				inputMode='decimal'
				allowNegative={false}
				decimalScale={decimalscale}
				prefix={prefix}
				format={showPrefix ? null : handleFormat}
				removeFormatting={handleRemoveFormatting}
				allowEmptyFormatting={showPrefix}
				onClick={() => setShowPrefix(true)}
				onFocus={() => {
					rest.onFocus();
					setShowPrefix(true);
				}}
				onBlurCapture={() => setShowPrefix(false)}
			/>
		</ClickAwayListener>
	);
};

CurrencyFormat.propTypes = {
	inputRef: PropTypes.func,
	onChange: PropTypes.func,
	decimalscale: PropTypes.number,
	returntypevalue: PropTypes.string,
	fixedDecimalScale: PropTypes.bool,
	thousandSeparator: PropTypes.string,
	decimalSeparator: PropTypes.string,
	prefix: PropTypes.string
};

CurrencyFormat.defaultProps = {
	inputRef: f => f,
	onChange: f => f,
	decimalscale: 0,
	fixedDecimalScale: true,
	returntypevalue: 'floatValue',
	thousandSeparator: ',',
	decimalSeparator: '.',
	prefix: '$'
};

export default CurrencyFormat;
