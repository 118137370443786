import React from 'react';
import { HeaderCardsComparison } from '../shared/HeaderCardsComparison';
import { toPercentage } from '../../utils/UtilHelper';
import './styles.scss';

export default function HeaderFIA({ guaranteedIncome, isJoint, isFIA }) {
	const percentage = toPercentage(guaranteedIncome, 0, false);
	let extraClass = percentage.length > 4 ? 'medium-text' : '';
	extraClass = percentage.length > 6 ? 'long-text' : extraClass;
	const annuityCardsData = {
		variableAnnuityCard: {
			vaChecked: true,
			vaText: (
				<div className='percentage-label'>
					<div
						className={`percent ${extraClass}`}
						style={{ fontFamily: 'Gotham-Light', fontSize: '50px' }}
					>
						{percentage}
					</div>
					<div className={`label ${extraClass}`} style={{ marginLeft: 8 }}>
						More Guaranteed Income <sup>1</sup>
					</div>
				</div>
			)
		},
		fixedIndexedAnnuityCard: {
			fiaChecked: true,
			fiaText: (
				<div className='percentage-label'>
					<div
						className='percent'
						style={{ fontFamily: 'Gotham-Light', fontSize: '50px' }}
					>
						100%
					</div>
					<div className='label' style={{ marginLeft: 8 }}>
						Protection from Market Losses
					</div>
				</div>
			)
		}
	};
	return (
		<div className='header-fia-wrapper'>
			<h3 className='result-fia'>
				Your client{isJoint ? 's' : ''} could get...
			</h3>
			<h1 className='result-fia'>More guaranteed income</h1>
			<label className='form-section-title title-results result-fia'>
				Based on the information you entered, New Heights Select and High Point 365 Select
				with Bonus would provide...
			</label>
			<HeaderCardsComparison {...annuityCardsData} isFIA={isFIA} />
		</div>
	);
}
