import React, { useState, useContext } from 'react';

import get from 'lodash/get';

import BrowserDetection from 'react-browser-detection';

const AppContext = React.createContext();

export function AppContextComponent({
	children,
	initialData
}) {
	const agreementAccepted = get(initialData, 'agreementAccepted', false);

	const defaultFooterName = get(initialData, 'footerName', 'home-footer');

	const defaultAnalyzerData = get(initialData, 'defaultAnalyzerData', null);

	const defaultUserInfo = get(initialData, 'userInfo', null);

	const auth = get(initialData, 'auth', null);

	const api = get(initialData, 'api', null);

	const [userAgrees, setUserAgrees] = useState(agreementAccepted);

	const [userInfo, setUserInfo] = useState(defaultUserInfo);

	const [footerName, setFooterName] = useState(defaultFooterName);

	const [analyzerPayload, setAnalyzerPayload] = useState(defaultAnalyzerData);

	const [browserType, setBrowserType] = useState(null);

	const state = {
		browserType,
		userAgrees,
		setUserAgrees,
		footerName,
		setFooterName,
		analyzerPayload,
		setAnalyzerPayload,
		userInfo,
		setUserInfo,
		auth,
		api
	};

	const browserHandler = {
		default: name => {
			setBrowserType(name);
			return null;
		}
	};

	return (
		<>
			<BrowserDetection>
				{browserHandler}
			</BrowserDetection>
			<AppContext.Provider value={{ ...state }}>
				{children}
			</AppContext.Provider>
		</>
	);
}

export const withContext = (newProps = {}) => ChildComponent => {
	return props => (
		<AppContext.Consumer>
			{context => {
				return <ChildComponent {...context} {...newProps} {...props} />;
			}}
		</AppContext.Consumer>
	);
};

export const useAppContext = () => {
	const context = useContext(AppContext);
	if (!context) {
		throw new Error(
			'useAppContext cannot be rendered outside the AppContext.Provider component'
		);
	}
	return context;
};
