import React from 'react';

export function NavMenuLogo() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
			width='155'
			height='59'
			viewBox='0 0 155 59'
		>
			<defs>
				<path
					id='mk33b'
					d='M226.057 40.49c.836 0 1.53.65 1.53 1.454 0 .804-.694 1.454-1.53 1.454-.835 0-1.527-.65-1.527-1.454 0-.803.692-1.454 1.527-1.454'
				></path>
				<path
					id='mk33c'
					d='M265.117 40.49c.836 0 1.529.65 1.529 1.454 0 .804-.693 1.454-1.529 1.454-.832 0-1.527-.65-1.527-1.454 0-.803.695-1.454 1.527-1.454'
				></path>
				<path
					id='mk33d'
					d='M215.538 48.093v5.445h-2.367v-.915c-.62.67-1.38 1.095-2.567 1.127-1.865.056-3.334-1.09-3.334-2.802 0-2.635 2.788-3.214 5.519-3.214h.307c0-.728-.322-1.402-1.763-1.402-.978 0-1.809.315-2.4.865l-1.432-1.44c.986-.977 2.371-1.477 3.902-1.477 1.515 0 2.347.215 3.193.981.8.722.942 2.079.942 2.832m-2.432 1.516h-.23c-.4 0-1.367.047-2.098.25-.503.138-.924.47-.924.932 0 .652.65.938 1.237.938 1.261 0 2.015-.803 2.015-1.843v-.277'
				></path>
				<path
					id='mk33e'
					d='M220.75 44.491V41.64h-2.551v2.851h-1.759v2.15h1.759v3.84c0 1.284.216 2.048.724 2.56.48.481 1.21.706 2.29.706.686 0 1.377-.136 1.913-.369v-2.035c-.323.11-.792.182-1.38.182-.792 0-.996-.436-.996-1.17V46.64h2.376v-2.15h-2.376'
				></path>
				<path
					id='mk33f'
					d='M288.233 49.177v.682h-6.58c.043.924 1.08 1.718 2.3 1.765 1.14.046 1.766-.408 2.354-1.05l1.622 1.195c-.985 1.254-2.204 1.983-3.93 1.983-2.735 0-4.969-1.713-4.969-4.735 0-2.418 1.494-4.737 4.65-4.737 3.248 0 4.553 2.539 4.553 4.897m-2.714-1.175c-.113-.985-.65-1.707-1.926-1.68-1.082.024-1.82.76-1.935 1.68h3.86'
				></path>
				<path
					id='mk33g'
					d='M202.92 40.41v8.91l-5.526-8.91h-3.454v13.124h2.81v-9.21l5.679 9.21h3.298V40.41h-2.807'
				></path>
				<path
					id='mk33h'
					d='M260.231 44.49l-1.448 5.617-1.448-5.617h-2.676l-1.399 5.604-1.448-5.604h-2.822l2.875 9.044h2.562l1.54-5.686 1.733 5.686h2.372l2.866-9.044h-2.707'
				></path>
				<path id='mk33i' d='M224.77 53.543h2.581V44.5h-2.581z'></path>
				<path id='mk33j' d='M263.83 53.543h2.583V44.5h-2.583z'></path>
				<path
					id='mk33k'
					d='M247.394 45.108c-.542-.569-1.217-.835-2.217-.828-.988.007-2.13.49-2.672 1.197v-.981h-2.475v9.041h2.583v-4.914c0-.639.173-1.111.473-1.445.262-.296.642-.483 1.125-.513.584-.033.896.221 1.118.506.276.351.352.966.352 1.793v4.573h2.58V47.89c0-1.104-.141-2.022-.867-2.783'
				></path>
				<path
					id='mk33l'
					d='M277.547 40.41v13.124h-2.433v-1.006c-.568.66-1.498 1.219-2.788 1.219-1.24 0-2.316-.42-3.112-1.221-.852-.858-1.324-2.108-1.324-3.513 0-2.746 1.828-4.737 4.351-4.737 1.104 0 2.02.341 2.722 1.01V40.41h2.584m-2.501 8.603c0-1.217-.784-2.447-2.289-2.447-1.499 0-2.282 1.23-2.282 2.447 0 1.212.783 2.444 2.282 2.444 1.505 0 2.289-1.232 2.289-2.444'
				></path>
				<path
					id='mk33m'
					d='M238.557 49.017c0 2.745-2.04 4.736-4.858 4.736-2.818 0-4.859-1.99-4.859-4.736 0-2.746 2.04-4.737 4.859-4.737 2.818 0 4.858 1.99 4.858 4.737m-4.858-2.444c-1.5 0-2.281 1.23-2.281 2.444 0 1.215.78 2.443 2.28 2.443 1.497 0 2.281-1.228 2.281-2.443s-.784-2.444-2.28-2.444'
				></path>
				<path
					id='mk33n'
					d='M289.385 43.648v-2.375h.906c.563 0 .828.257.828.715 0 .414-.242.615-.562.654l.612 1.006h-.452l-.587-.972h-.315v.972zm.43-1.346h.315c.266 0 .559-.016.559-.334 0-.317-.293-.333-.559-.328h-.315zm-1.695.167c0-1.14.922-2.069 2.055-2.069 1.132 0 2.054.93 2.054 2.07 0 1.14-.922 2.068-2.054 2.068a2.065 2.065 0 01-2.055-2.069zm.365-.005a1.697 1.697 0 101.695-1.697c-.937 0-1.695.76-1.695 1.697z'
				></path>
				<path id='mk33a' d='M138.52 15.23h43.631v58.745H138.52z'></path>
				<path
					id='mk33p'
					d='M172.219 27.97v22.485l-2.778-2.739v8.662c0 1.996-.18 3.305-1.134 4.505l-.022.027c-.048.056-.088.107-.135.162l3.148 2.987h10.857V27.97h-9.936'
				></path>
				<path
					id='mk33q'
					d='M148.436 42.249l4.773 4.553c-.416-1.56-.705-3.631.133-4.984l.45-.726c-.456-2.462-.34-5.017 1.199-6.717l.403-.446-6.065-5.959H138.5v36.09h9.936V42.249'
				></path>
				<path
					id='mk33r'
					d='M167.573 60.285c-1.106 1.291-2.54 3.128-2.23 4.468.23 1.11 1.785 1.792 1.785 1.792l3.341 1.824-2.168 1.906 2.601.826s-2.384 2.462-2.659 2.71a12.94 12.94 0 01-2.178.184c-5.52 0-13.196-3.281-15.095-3.305-2.29-.028-5.166 1.301-5.166 1.301l1.502-1.637s-1.966.401-3.872.238l2.687-1.838c-2.36-.077-4.427-.785-5.401-1.478 0 0 4.345-.347 10.238-.283 2.384.025 6.426.266 6.426.266-1.998-.855-3.866-1.981-4.668-3.961l3.81.578c-1.6-.986-3.922-3.456-4.35-5.368l4.249 1.5c-.754-.824-3.382-3.301-3.854-6.173l3.363 2.13c-.882-.893-3.27-5.64-2.864-7.028l3.134 2.123c-1.5-1.869-3.256-6.82-2.06-8.748.952 3.731 3.073 5.09 3.073 5.09-1.393-2.032-4.396-9.228-1.528-12.397.753 7.177 3.783 9.6 3.783 9.6-2.508-4.025-5.364-16.379-.036-18.877-1.043 7.937 1.475 12.252 1.475 12.252-1.59-7.313-1.537-17.675 3.86-18.621-2.65 7.812-.768 13.836-.768 13.836-.387-18.273 5.459-17.965 5.459-17.965s-.961 5.189-.961 14.436V56.37c0 1.844-.157 2.943-.928 3.914m3.468 6.464c2.35.183 4.019.34 5.695.553.634.08.886.819.97 1.165-.803-.2-2.072-.254-2.072-.254l-.07.767s1.085-.313 2.274-.327c0 0 2.438-.01 2.321 1.649-.665-.464-2.026-.28-2.41-.216-1.224.203-2.39.775-3.737 1.493-1.234.656-2.564 1.363-4.127 1.841.684-.67 2.473-2.66 2.473-2.66l-2.384-.837 1.952-1.716-3-1.637c.75.064 1.457.129 2.115.18'
				></path>
				<clipPath id='mk33o'>
					<use xlinkHref='#mk33a'></use>
				</clipPath>
			</defs>
			<use
				fill='#fffffe'
				transform='translate(-138 -15)'
				xlinkHref='#mk33b'
			></use>
			<use
				fill='#fffffe'
				transform='translate(-138 -15)'
				xlinkHref='#mk33c'
			></use>
			<use
				fill='#fffffe'
				transform='translate(-138 -15)'
				xlinkHref='#mk33d'
			></use>
			<use
				fill='#fffffe'
				transform='translate(-138 -15)'
				xlinkHref='#mk33e'
			></use>
			<use
				fill='#fffffe'
				transform='translate(-138 -15)'
				xlinkHref='#mk33f'
			></use>
			<use
				fill='#fffffe'
				transform='translate(-138 -15)'
				xlinkHref='#mk33g'
			></use>
			<use
				fill='#fffffe'
				transform='translate(-138 -15)'
				xlinkHref='#mk33h'
			></use>
			<use
				fill='#fffffe'
				transform='translate(-138 -15)'
				xlinkHref='#mk33i'
			></use>
			<use
				fill='#fffffe'
				transform='translate(-138 -15)'
				xlinkHref='#mk33j'
			></use>
			<use
				fill='#fffffe'
				transform='translate(-138 -15)'
				xlinkHref='#mk33k'
			></use>
			<use
				fill='#fffffe'
				transform='translate(-138 -15)'
				xlinkHref='#mk33l'
			></use>
			<use
				fill='#fffffe'
				transform='translate(-138 -15)'
				xlinkHref='#mk33m'
			></use>
			<use
				fill='#fffffe'
				transform='translate(-138 -15)'
				xlinkHref='#mk33n'
			></use>
			<g clipPath='url(#mk33o)' transform='translate(-138 -15)'>
				<use fill='#fffffe' xlinkHref='#mk33p'></use>
			</g>
			<g clipPath='url(#mk33o)' transform='translate(-138 -15)'>
				<use fill='#fffffe' xlinkHref='#mk33q'></use>
			</g>
			<g clipPath='url(#mk33o)' transform='translate(-138 -15)'>
				<use fill='#fffffe' xlinkHref='#mk33r'></use>
			</g>
		</svg>
	);
}
