import React, { useState } from 'react';
import { GroupedBar } from './groupedBar';
import { normalizeValue } from './helper';
import BrowserDetection from 'react-browser-detection';
import { MAX_BAR_HEIGHT_MOBILE } from '../../../constants';
import './styles.scss';

export function PortraitGroupedBars({
	config,
	width = 100,
	height = 100,
	highestGroupValue,
	maxSizePercentage,
	isFIA
}) {
	const baseConfig = {
		width: `${width}%` || '100%',
		height: `${height}%` || '100%',
		viewBox: '0 0 278 425',
		maxBarHeight: MAX_BAR_HEIGHT_MOBILE,
		maxWidth: 270,
		maxWidthPerGroup: 140,
		barWidth: 44
	};

	const [browserTranslate, setBrowserTranslate] = useState({
		labelY: 338
	});

	const browserHandler = {
		ie: () => {
			setBrowserTranslate({
				labelY: 348
			});
			return (null);
		},
		edge: () => {
			setBrowserTranslate({
				labelY: 348
			});
			return (null);
		},
	};

	const [firstGroupConfig, secondGroupConfig] = config;

	const renderChart = (groupConfig, isSecondGroup) => {
		const { data } = groupConfig;
		return data.map((datum, index) => {
			const key = isSecondGroup ? `bar-group-${index}` : `bar-alone-${index}`;
			return (<GroupedBar
				key={key}
				index={index}
				dataLength={data.length}
				isSecondGroup={isSecondGroup}
				highestGroupValue={highestGroupValue}
				maxSizePercentage={maxSizePercentage}
				normalizeValue={normalizeValue(baseConfig.maxBarHeight)}
				{...datum}
				{...baseConfig} />);
		});
	};

	return (
		<div className='vertical-grouped-bar-chart-wrapper'>
			<BrowserDetection>
				{browserHandler}
			</BrowserDetection>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				preserveAspectRatio='none'
				width={baseConfig.width}
				height={baseConfig.height}
				viewBox={baseConfig.viewBox}
			>
				<defs>
					<linearGradient
						id='4gqua'
						x1='-7.5'
						x2='312.5'
						y1='165.5'
						y2='165.5'
						gradientUnits='userSpaceOnUse'
					>
						<stop offset='0' stopColor='#fff' stopOpacity='0' />
						<stop offset='1' stopOpacity='.03' />
					</linearGradient>
					<linearGradient
						id='4gqub'
						x1='174'
						x2='-7'
						y1='71'
						y2='71'
						gradientUnits='userSpaceOnUse'
					>
						<stop offset='0' stopColor='#fff' stopOpacity='0' />
						<stop offset='1' stopOpacity='.15' />
					</linearGradient>
					<linearGradient
						id='4gquc'
						x1='194'
						x2='-7'
						y1='130'
						y2='130'
						gradientUnits='userSpaceOnUse'
					>
						<stop offset='0' stopColor='#fff' stopOpacity='0' />
						<stop offset='1' stopOpacity='.15' />
					</linearGradient>
					<linearGradient
						id='4gqud'
						x1='266'
						x2='-7'
						y1='201'
						y2='201'
						gradientUnits='userSpaceOnUse'
					>
						<stop offset='0' stopColor='#fff' stopOpacity='0' />
						<stop offset='1' stopOpacity='.15' />
					</linearGradient>
					<mask id='4gquf' width='2' height='2' x='-1' y='-1'>
						<path fill='#fff' d='M8 87h77v54H8z' />
						<path d='M163 32.9a3 3 0 0 1 3-3h70a3 3 0 0 1 3 3v38a3 3 0 0 1-3 3h-25.973l-10 10-10-10H166a3 3 0 0 1-3-3z' />
					</mask>
					<filter
						id='4gque'
						width='109'
						height='89'
						x='-8'
						y='71'
						filterUnits='userSpaceOnUse'
					>
						<feOffset dy='3' in='SourceGraphic' result='FeOffset1361Out' />
						<feGaussianBlur
							in='FeOffset1361Out'
							result='FeGaussianBlur1362Out'
							stdDeviation='2.4 2.4'
						/>
					</filter>
					<linearGradient
						id='4gqug'
						x1='238'
						x2='-7'
						y1='259'
						y2='259'
						gradientUnits='userSpaceOnUse'
					>
						<stop offset='0' stopColor='#fff' stopOpacity='0' />
						<stop offset='1' stopOpacity='.15' />
					</linearGradient>
					<linearGradient
						id='4gquh'
						x1='104.5'
						x2='207.5'
						y1='375'
						y2='375'
						gradientUnits='userSpaceOnUse'
					>
						<stop offset='0' stopColor='#fff' stopOpacity='0' />
						<stop offset='1' stopOpacity='.08' />
					</linearGradient>
					<clipPath id='4gqui'>
						<path d='M42 171h18v24H42z' />
					</clipPath>
				</defs>
				<g transform='translate(-16,-2)'>
					<g>
						<g transform='rotate(-90 152.5 163)'>
							<g>
								<path
									fill='#f9fafb'
									d='M-7.5 26.5h316a4 4 0 0 1 4 4v270a4 4 0 0 1-4 4h-316z'
								/>
								<path
									fill='url(#4gqua)'
									d='M-7.5 26.5h316a4 4 0 0 1 4 4v270a4 4 0 0 1-4 4h-316z'
								/>
							</g>
							<g opacity='.5'>
								<path
									fill='none'
									stroke='#979797'
									strokeDasharray='8 4'
									strokeLinecap='square'
									strokeMiterlimit='10'
									strokeWidth='.5'
									d='M90 302.5v-274'
								/>
							</g>
							<g opacity='.5'>
								<path
									fill='none'
									stroke='#979797'
									strokeDasharray='8 4'
									strokeLinecap='square'
									strokeMiterlimit='10'
									strokeWidth='.5'
									d='M31.5 302V29'
								/>
							</g>
							<g opacity='.5'>
								<path
									fill='none'
									stroke='#979797'
									strokeDasharray='8 4'
									strokeLinecap='square'
									strokeMiterlimit='10'
									strokeWidth='.5'
									d='M148.5 302V29'
								/>
							</g>
							<g opacity='.5'>
								<path
									fill='none'
									stroke='#979797'
									strokeDasharray='8 4'
									strokeLinecap='square'
									strokeMiterlimit='10'
									strokeWidth='.5'
									d='M207.5 302V29'
								/>
							</g>
							<g opacity='.5'>
								<path
									fill='none'
									stroke='#979797'
									strokeDasharray='8 4'
									strokeLinecap='square'
									strokeMiterlimit='10'
									strokeWidth='.5'
									d='M266.5 302V29'
								/>
							</g>
							<g />
							<g>
								{renderChart(firstGroupConfig)}
								{renderChart(secondGroupConfig, true)}
							</g>
						</g>
						<g transform='rotate(-90 155.5 375)'>
							<g>
								<path
									fill='#fff'
									d='M104.5 239.5a4 4 0 0 1 4-4h99v279h-99a4 4 0 0 1-4-4z'
								/>
								<path
									fill='url(#4gquh)'
									d='M104.5 239.5a4 4 0 0 1 4-4h99v279h-99a4 4 0 0 1-4-4z'
								/>
							</g>
							<g>
								<path
									fill='#1c57a5'
									fillOpacity='.5'
									d='M103.5 237.5a2 2 0 0 1 2-2h2v278h-2a2 2 0 0 1-2-2z'
								/>
							</g>
						</g>
						<g>
							<g>
								<text
									dominantBaseline='text-before-edge'
									style={{ fontKerning: 'normal' }}
									fill='#555'
									fontFamily="'Lato','Lato-Bold'"
									fontSize='14'
									transform='translate(50 385)'
								>
									<tspan fontFamily="'Lato','Lato-Regular'">at</tspan>
									<tspan style={{ fontWeight: 'bold' }} fontFamily="'Lato','Lato-Bold'"> Age 75</tspan>
								</text>
							</g>
						</g>
						<g>
							<g>
								<text
									dominantBaseline='text-before-edge'
									style={{ fontKerning: 'normal' }}
									fill='#555'
									fontFamily="'Lato','Lato-Bold'"
									fontSize='14'
									transform='translate(190 385)'
								>
									<tspan fontFamily="'Lato','Lato-Regular'">at</tspan>
									<tspan style={{ fontWeight: 'bold' }} fontFamily="'Lato','Lato-Bold'"> Age 65</tspan>
								</text>
							</g>
						</g>
						<g>
							<path fill='#8aa7ce' d='M155 323h1v100h-1z' />
						</g>
						<g>
							<g>
								<g>
									<text
										dominantBaseline='text-before-edge'
										style={{ fontKerning: 'normal' }}
										fill='#555'
										fontFamily="'Lato','Lato-Bold'"
										fontSize='14'
										transform={`translate(27 ${browserTranslate.labelY})`}
									>
										{isFIA ?
											<>
												<tspan fontSize='11' y={0} style={{ fontKerning: 'normal', fontWeight: 'bold' }}>Nationwide</tspan>
												<tspan fontSize='11' y={15} x={20} style={{ fontKerning: 'normal', fontWeight: 'bold' }}>FIA</tspan>
											</>
											:
											<tspan y={0} x={15} style={{ fontKerning: 'normal', fontWeight: 'bold' }}>FIA</tspan>
										}
										
									</text>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<text
										dominantBaseline='text-before-edge'
										style={{ fontKerning: 'normal' }}
										fill='#555'
										fontFamily="'Lato','Lato-Bold'"
										fontSize='14'
										transform={`translate(95 ${browserTranslate.labelY})`}
									>
										{isFIA ?
											<>
												<tspan fontSize='11' y={0} style={{ fontKerning: 'normal', fontWeight: 'bold' }}>Current</tspan>
												<tspan fontSize='11' y={15} x={12} style={{ fontKerning: 'normal', fontWeight: 'bold' }}>FIA</tspan>
											</>
											:
											<tspan y={0} x={12} style={{ fontKerning: 'normal', fontWeight: 'bold' }}>VA</tspan>
										}
									</text>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<text
										dominantBaseline='text-before-edge'
										style={{ fontKerning: 'normal' }}
										fill='#555'
										fontFamily="'Lato','Lato-Bold'"
										fontSize='14'
										transform={`translate(170 ${browserTranslate.labelY})`}
									>
										{isFIA ?
											<>
												<tspan fontSize='11' y={0} style={{ fontKerning: 'normal', fontWeight: 'bold' }}>Nationwide</tspan>
												<tspan fontSize='11' y={15} x={20} style={{ fontKerning: 'normal', fontWeight: 'bold' }}>FIA</tspan>
											</>
											:
											<tspan y={0} x={15} style={{ fontKerning: 'normal', fontWeight: 'bold' }}>FIA</tspan>
										}
									</text>
								</g>
							</g>
						</g>
						<g>
							<g>
								<g>
									<text
										dominantBaseline='text-before-edge'
										style={{ fontKerning: 'normal' }}
										fill='#555'
										fontFamily="'Lato','Lato-Bold'"
										fontSize='14'
										transform={`translate(235 ${browserTranslate.labelY})`}
									>
										{isFIA ?
											<>
												<tspan fontSize='11' y={0} style={{ fontKerning: 'normal', fontWeight: 'bold' }}>Current</tspan>
												<tspan fontSize='11' y={15} x={12} style={{ fontKerning: 'normal', fontWeight: 'bold' }}>FIA</tspan>
											</>
											:
											<tspan y={0} x={12} style={{ fontKerning: 'normal', fontWeight: 'bold' }}>VA</tspan>
										}
									</text>
								</g>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</div>
	);
};