import moment from 'moment';
export const toCurrency = value => new Intl.NumberFormat('en-US', {
	style: 'currency', currency: 'USD',
	minimumFractionDigits: 0
}).format(Math.round(value));

export const toPercentage = (value, digits = 2, formatBy = true) => `${new Intl.NumberFormat('en-US', {
	minimumFractionDigits: digits,
	maximumFractionDigits: digits,
}).format(formatBy ? value * 100 : value)}%`;

export const currentYear = () => moment().year();