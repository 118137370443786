import React, { useEffect, useState } from 'react';
import { useResize } from 'react-browser-hooks';
import { ResultsBarChart } from '../ResultsBarChart';
import { ORIENTATION } from '../../../constants';
import PropTypes from 'prop-types';
import './styles.scss';

export function ResultsCard({
	title,
	sectionKey,
	chartConfig,
	highestGlobalValue,
	maxSizePercentage,
	description,
	descriptionFIA,
	isFIA,
}) {
	const [orientation, setOrientation] = useState(ORIENTATION.HORIZONTAL);
	const { width } = useResize();

	useEffect(() => {
		setOrientation(width < 768 ? ORIENTATION.VERTICAL : ORIENTATION.HORIZONTAL);
	}, [width, setOrientation]);

	return (
		<div
			className={`results-card-wrapper ${
				orientation === ORIENTATION.VERTICAL
					? 'results-card-wrapper-vertical'
					: ''
			}`}
		>
			<label className='form-section-title'>
				{title.title}
				{title.superscript && <sup>{title.superscript}</sup>}
			</label>
			<div className='card-wrapper'>
				{
					<ResultsBarChart
						sectionKey={sectionKey}
						chartConfig={chartConfig}
						orientation={orientation}
						maxSizePercentage={maxSizePercentage}
						highestGlobalValue={highestGlobalValue}
						isFIA={isFIA}
					/>
				}
			</div>
			{description && <p>{isFIA ? descriptionFIA : description}</p>}
		</div>
	);
}

ResultsCard.propTypes = {
	title: PropTypes.object,
	chartConfig: PropTypes.oneOfType([
		PropTypes.shape({
			bar: PropTypes.shape({
				width: PropTypes.number,
				margin: PropTypes.number
			}),
			sidebar: PropTypes.shape({
				age: PropTypes.number
			}),
			data: PropTypes.arrayOf(
				PropTypes.shape({
					legend: PropTypes.string,
					value: PropTypes.number,
					color: PropTypes.string,
					type: PropTypes.string,
					checked: PropTypes.bool,
					label: PropTypes.string,
				})
			)
		}),
		PropTypes.arrayOf(
			PropTypes.shape({
				bar: PropTypes.shape({
					width: PropTypes.number,
					margin: PropTypes.number
				}),
				sidebar: PropTypes.shape({
					age: PropTypes.number
				}),
				data: PropTypes.arrayOf(
					PropTypes.shape({
						legend: PropTypes.string,
						value: PropTypes.number,
						color: PropTypes.string,
						type: PropTypes.string,
						checked: PropTypes.bool,
						label: PropTypes.string,
					})
				)
			}),
		)
	])
};
