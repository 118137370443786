import React from 'react';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { getStartingPositionInX } from './helper';

const BarTooltip = withStyles({
	tooltip: {
		fontSize: 18
	}
})(Tooltip);

export function PortraitBar({
	value,
	label,
	color,
	index,
	dataLength,
	maxWidth,
	barWidth
}) {
	const barPadding = 15;
	const startingPositionInX = getStartingPositionInX(dataLength, maxWidth, barWidth, index, barPadding);

	return (
		<BarTooltip
			arrow
			open={true}
			title={label}
			tabIndex='-1'
			placement='top'
			PopperProps={{
				modifiers: {
					flip: {
						enabled: false
					}
				}
			}}
			disableFocusListener
			leaveTouchDelay={5000}
		>
			<g>
				<g>
					<path
						fill={color}
						d={`M-11 ${startingPositionInX}h${value}a4 4 0 0 1 4 4v73a4 4 0 0 1-4 4H-11z`}
					/>
					<path
						fill='url(#c9d6b)'
						d={`M-11 ${startingPositionInX}h${value}a4 4 0 0 1 4 4v73a4 4 0 0 1-4 4H-11z`}
					/>
				</g>
			</g>
		</BarTooltip>
	);
}