import moment from 'moment';

import _ from 'lodash';

export const sanitizePayload = analyzerPayload => {
	if (!analyzerPayload || !analyzerPayload.input) {
		return {};
	}

	const { input } = analyzerPayload;

	const contractPurchaseDate = moment(input.ContractPurchaseDate);

	return {
		...input,
		IncomeBaseRollupRate: input.IncomeBaseRollupRate * 100,
		PayoutIncomeStart: input.PayoutIncomeStart * 100,
		Month: contractPurchaseDate.month() + 1,
		Day: contractPurchaseDate.date(),
		Year: contractPurchaseDate.year(),
		JointCurrentAge: _.get(input, 'JointInfo.CurrentAge', ''),
		NoRollUp: input.NoRollUpEndDate,
		JointLifeTimeIncomeStartAge: _.get(
			input,
			'JointInfo.LifeTimeIncomeStartAge',
			''
		)
	};
};

export const getInitialValues = (override = {}) => {
	return Object.assign(
		{
			CurrentAge: '',
			LifeTimeIncomeStartAge: '',
			OwnerType: undefined,
			State: undefined,
			JointCurrentAge: '',
			JointLifeTimeIncomeStartAge: '',
			Carrier: undefined,
			ProductName: '',
			Month: undefined,
			Day: undefined,
			Year: undefined,
			ContractCashValue: '',
			IncomeBenefitBase: '',
			IncomeBaseRollupRate: '',
			TotalRollupPeriodTerm: '',
			PayoutIncomeStart: '',
			CalculationMethod: undefined,
			NoRollUp: false,
			SurrenderValue: '',
			InitialIncomeBenefitBase: ''
		},
		override
	);
};

export const mapToDropDown = (collection, valueKey, textKey) => {
	const rawArray = !valueKey || !textKey;
	return collection.map(item => {
		if (rawArray) {
			return {
				value: item,
				text: item
			};
		}
		const value = item[valueKey];
		const text = item[textKey];
		return { value, text };
	});
};

export const getFormDate = (year, month, day) => {
	return moment(`${month}-${day}-${year}`, 'MM-DD-YYYY');
};

export const getFormValueAsNumericPercentage = value => {
	return _.isNumber(value) ? parseFloat(value) / 100 : 0;
};

export const focusFirstError = (form, conditionalCallback) => {
	const fields = Object.keys(form);
	for (let i = 0, len = fields.length; i < len; i++) {
		const key = fields[i];
		const currentField = form[key];
		if (conditionalCallback(currentField)) {
			currentField.focus();
			break;
		}
	}
};
