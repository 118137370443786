import React from 'react';

const HintSvg = props => (
	<svg
		width='18'
		height='18'
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 18 18'
		style={{ height: '16px', width: '19px' }}
	>
		<g>
			<g>
				<path
					d='M8.088 15c-.575 0-.995-.402-.593-2.17l.658-3.14c.114-.503.134-.704 0-.704a4.1 4.1 0 0 0-1.361.689L6.5 9.132C7.538 8.043 8.817 7.306 10.194 7c.575 0 .67.785.383 1.99l-.751 3.3c-.134.582-.076.785.057.785.477-.122.92-.377 1.291-.744l.326.503c-.897 1.14-2.098 1.901-3.412 2.166zm2.184-11.986c.328-.056.66.06.9.315s.358.618.321.983c-.013.474-.2.92-.52 1.236-.32.315-.742.47-1.169.43a1.028 1.028 0 0 1-.947-.297 1.29 1.29 0 0 1-.352-1.018A1.84 1.84 0 0 1 9.07 3.42a1.496 1.496 0 0 1 1.202-.406zM9.377 0a9 9 0 1 0 .246 17.998A9 9 0 0 0 9.377.001z'
					{...props}
				/>
			</g>
		</g>
	</svg>
);

export default HintSvg;
