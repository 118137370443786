import React from 'react';

export function YearsOfDeferralLabel({ lifeTimeIncomeStartAge, currentAge }) {
	const diff = lifeTimeIncomeStartAge - currentAge;

	if (diff <= 0) {
		return null;
	}

	const legend = `year${diff > 1 ? 's' : ''}`;

	return (
		<div>
			<label className='years-of-deferral'>
				{diff} {legend} of deferral
			</label>
		</div>
	);
}
